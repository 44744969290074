import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(private httpRequest: HttpRequestService) { }
  
  public HELP_BASE_URL:string = "https://dev.docs.tenupsoft.com/recruit";
}
