import { Injectable } from '@angular/core';
import { JobDescriptionService } from '../../core/services/job-description.service';
import { JobTitleService } from '../../core/services/job-title.service';
import { JobPositionService } from '../../core/services/job-position.service';
import { OrganizationService } from '../../core/services/organization.service';
import { DepartmentService } from '../../core/services/department.service';
import { UserService } from '../../core/services/user.service';
import { SkillService } from '../../core/services/skill.service';
import { QualificationService } from '../../core/services/qualification.service';
import { RecruiterService } from '../../core/services/recruiter.service';
import { JobRequisitionService } from '../../core/services/job-requisition.service';
import { SecurityService } from '../../core/services/security.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
class DataCachingService {

  constructor(
    private _jobDescriptionService: JobDescriptionService,
    private _jobTitleService: JobTitleService,
    private _jobPositionService: JobPositionService,
    private _organizationService: OrganizationService,
    private _departmentService: DepartmentService,
    private _userService: UserService,
    private _skillService: SkillService,
    private _qualificationService: QualificationService,
    private _recruiterService: RecruiterService,
    private _jobRequisitionService: JobRequisitionService,
    private _securityService: SecurityService
  ) { }

  fetchAll() {
    this._securityService.loadAppAccessPermissions();
    if (!sessionStorage.getItem('cached')) {
      // let jobDescriptions = this._jobDescriptionService.fetchJobDescriptions();
      // let jobTitles = this._jobTitleService.fetchJobTitles();
      // let jobPositions = this._jobPositionService.fetchJobPositions();
      // let organizations = this._organizationService.fetchOrganization();
      // let departments = this._departmentService.fetchDepartments();
      // let employees = this._userService.fetchEmployees();
      // let qualifications = this._qualificationService.fetchQualifications();
      // let skills = this._skillService.fetchSkills();
      // let recruiters = this._recruiterService.fetchRecruiters();
      // let jobRequisitions = this._jobRequisitionService.fetchJobRequisitions();
      // forkJoin([jobDescriptions, jobTitles, organizations, departments, employees, qualifications, skills, recruiters, jobRequisitions, jobPositions]).subscribe(results => {
      //   sessionStorage.setItem('jobDescriptions', JSON.stringify(results[0]));
      //   sessionStorage.setItem('jobTitles', JSON.stringify(results[1]));
      //   sessionStorage.setItem('organizations', JSON.stringify(results[2]));
      //   sessionStorage.setItem('departments', JSON.stringify(results[3]));
      //   sessionStorage.setItem('employees', JSON.stringify(results[4]));
      //   sessionStorage.setItem('qualifications', JSON.stringify(results[5]));
      //   sessionStorage.setItem('skills', JSON.stringify(results[6]));
      //   sessionStorage.setItem('recruiters', JSON.stringify(results[7]));
      //   sessionStorage.setItem('jobRequisitions', JSON.stringify(results[8]));
      //   sessionStorage.setItem('jobPositions', JSON.stringify(results[9]));
      //   sessionStorage.setItem('cached', 'true');
      // });

      let jobTitles = this._jobTitleService.fetchJobTitles();
      let organizations = this._organizationService.fetchOrganization();
      let departments = this._departmentService.fetchDepartments();
      let jobPositions = this._jobPositionService.fetchJobPositions();
      let qualifications = this._qualificationService.fetchQualifications();
      let skills = this._skillService.fetchSkills();
      let jobRequisitions = this._jobRequisitionService.fetchJobRequisitions();
      let employees = this._userService.fetchEmployees();
      let recruiters = this._recruiterService.fetchRecruiters();
      forkJoin([ jobTitles, organizations, departments,jobPositions,skills,qualifications,jobRequisitions,employees,recruiters]).subscribe(results => {
        sessionStorage.setItem('jobTitles', JSON.stringify(results[0]));
        sessionStorage.setItem('organizations', JSON.stringify(results[1]));
        sessionStorage.setItem('departments', JSON.stringify(results[2]));
        sessionStorage.setItem('jobPositions', JSON.stringify(results[3]));
        sessionStorage.setItem('skills', JSON.stringify(results[4]));
        sessionStorage.setItem('qualifications', JSON.stringify(results[5]));
        sessionStorage.setItem('jobRequisitions', JSON.stringify(results[6]));
        sessionStorage.setItem('employees', JSON.stringify(results[7]));
        sessionStorage.setItem('recruiters', JSON.stringify(results[8]));
        sessionStorage.setItem('cached', 'true');
      });
    }
  }

  refreshAll() {
    sessionStorage.removeItem('cached');
    this.fetchAll();
  }
}

function fetchCachedData(dataType) {
  return sessionStorage.getItem(dataType) ? JSON.parse(sessionStorage.getItem(dataType)) : [];
}

export { fetchCachedData, DataCachingService };