import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { PeopleActivityService } from 'src/app/core/services/people-activity.service';

@Component({
  selector: 'app-people-activity',
  templateUrl: './people-activity.component.html',
  styleUrls: ['./people-activity.component.css']
})
export class PeopleActivityComponent implements OnInit {
  allActivities: [];
  spinner = true;
  constructor(private peopleActivityService: PeopleActivityService, private employeeService: EmployeeService) { }
  peopleActivities = [''];
  peopleActivity = [];
  allActivity = [];
  uniqueEmployeeId = [];
  profilePic = [];
  employeesId = [];
  pageNo = 0;
  pageSize = 100;

  img = "../../../assets/images/avatar.jpg";



  ngOnInit(): void {
    this.getEmployeeId(this.pageNo, this.pageSize, 'All Activity');
  }
  getEmployeeId(pageNo: number, pageSize: number, event) {
    this.peopleActivityService.getpeopleActivities('EMPLOYEE_ACTIVITY', pageNo, pageSize).subscribe(res => {
      res?.listOfEmployeeActivity?.find(res => {
        this.employeesId.push(res.employeeId)
        this.fetchEmpProfilePic(res.employeeId, event);
      });
    });
  }


  getPeopleActivity(pageNo, pageSize, event) {
    let activity;
    switch (event) {
      case "All Activity":
        activity = ['EMPLOYEE_ACTIVITY'];
        break;
      default:
    }
    this.peopleActivityService.getpeopleActivities(activity, pageNo, pageSize).subscribe(res => {
      this.spinner = false;
      console.log(res['listOfEmployeeActivity']);
      // this.peopleActivity = res['listOfEmployeeActivity'];
      res?.listOfEmployeeActivity.find(res => {
        res.employeeProfilePic = this.profilePic[this.uniqueEmployeeId.indexOf(res.employeeId)]
      });
      this.allActivities = res?.listOfEmployeeActivity;
      res.listOfEmployeeActivity.filter(res => {
        this.peopleActivity.push(res);
        this.peopleActivities.push(res);
      });
    });
  }

  loadMoreActivity(event) {
    this.peopleActivities = []
    this.pageNo++;
    this.getPeopleActivity(this.pageNo, this.pageSize, event)

  }

  fetchEmpProfilePic(employeeId, event) {
    this.employeeService.fetchEmpProfilePic(employeeId).subscribe((res: any) => {
      this.uniqueEmployeeId.push(employeeId);
      if (res.isProfileImageExist) {
        this.profilePic.push(res.imageBase64Encoded)
      }
      else {
        this.profilePic.push("../../../assets/images/avatar.jpg")
      }
      if (this.employeesId.length == this.uniqueEmployeeId.length) {

        this.getPeopleActivity(this.pageNo, this.pageSize, event);

      }
    });
  }
}


