import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AssetAllocation } from 'src/app/shared/models/asset-allocation';
import { AssetManagement } from 'src/app/shared/models/asset-management';
import { Assetallocation } from 'src/app/shared/models/assetallocation';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class AssetManagementService {
  private stateURL = 'api/v1/asset';
  private stateUrlsu = 'api/v1/supplier';
  private stateacation = 'api/v1/asset';
  private requestUrl = 'api/vi/asset/action/request';
  router: any;


  constructor(private httpRequest: HttpRequestService, router: Router) { }

  getAllApproverList(): Observable<[]> {
    return this.httpRequest.get(this.requestUrl);
  }


  getAllAssetlist(): Observable<AssetManagement[]> {
    return this.httpRequest.get(this.stateURL);
  }

  gotoasset() {
    this.router.navigate(['/Asset'])
  }
  getSupplierList(): Observable<AssetManagement[]> {
    return this.httpRequest.get(this.stateUrlsu);
  }

  createAsset(asset: AssetManagement): Observable<any> {
    return this.httpRequest.post(this.stateURL, asset);
  }

  getAssetById(id: number): Observable<AssetManagement> {
    return this.httpRequest.get(this.stateURL + "/" + id);
  }

  updateAsset(id: number, AssetManagement: AssetManagement): Observable<Object> {
    return this.httpRequest.put(this.stateURL + "/" + id, JSON.stringify(AssetManagement));
  }

  deleteAsset(id: number): Observable<Object> {
    return this.httpRequest.delete(this.stateURL + "/" + id);
  }

  assetallocateemployee(assetAllocation): Observable<any> {
    return this.httpRequest.post(this.stateacation + "/action", assetAllocation);
  }

  fetchAssetSearch(searchFormData): Observable<AssetManagement[]> {
    return this.httpRequest.post('api/v1/asset/filter', searchFormData);
  }
}