import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { JobPositionModel } from '../../shared/models/job-position-model';

@Injectable({
  providedIn: 'root'
})
export class JobPositionService {

  private stateUrl = 'api/v1/job-position';

  constructor(private httpRequest: HttpRequestService) { }

  fetchJobPositions(): Observable<JobPositionModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchJobPosition(jobPositionId): Observable<JobPositionModel> {
    return this.httpRequest.get(this.stateUrl + '/' + jobPositionId);
  }

  updateJobPosition(jobPositionModel: JobPositionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, jobPositionModel);
  }

  addJobPosition(jobPositionModel: JobPositionModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, jobPositionModel);
  }

  fetchJobPositionsWithJobTitleId(jobPositionId): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/title/' + jobPositionId);
  }
}
