import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SSOGatewayService } from '../../core/services/sso-gateway.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataCachingService } from 'src/app/shared/factory/data-caching.service';

@Component({
  selector: 'app-login',
  templateUrl: './sso-gateway.component.html',
 // styleUrls: ['./login.component.css']
})
export class SSOGatewayComponent implements OnInit {

  hide = true;
  returnUrl;

  constructor(
    private ssoGatewayService: SSOGatewayService,
    public router: Router,
    private route: ActivatedRoute,
    private dataCachinSeervice: DataCachingService
  ) {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (sessionStorage.getItem('isLoggedIn')) {
      this.router.navigate([this.returnUrl ? this.returnUrl : '/']);
    }
  }

  ngOnInit() {
   this.getUserInfo();
  }

 
 getJsonFromUrl() {
    var query = location.search.substr(1);
    var result = {};
    query.split("&").forEach(function(part) {
      var item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }
  getUserInfo() {
    var queryParams=this.getJsonFromUrl();
    sessionStorage.setItem('x-auth-token', queryParams['x_auth_token']);
    this.ssoGatewayService.getUserInfo().subscribe(res => {
      sessionStorage.setItem('isLoggedIn', 'true');
      sessionStorage.setItem('loginData', JSON.stringify(res));
     this.dataCachinSeervice.fetchAll();
     setTimeout(() => {
      this.router.navigate([this.returnUrl ? this.returnUrl : '/']);
     },1000)
      
  });  
   

  }

}
