import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { supplierModel } from 'src/app/shared/models/add-supplier';

import { HttpRequestService } from '..';

@Injectable({
  providedIn: 'root'
})
export class supplierModelService {
  private stateUrlsu = 'api/v1/supplier';
  private stateUrlsul = 'api/v1/supplier/id';
  constructor(private httpRequest: HttpRequestService, router: Router) { }


  getSupplierLists(): Observable<supplierModel[]> {
    return this.httpRequest.get(this.stateUrlsu);
  }
  getSupplierList(): Observable<supplierModel> {
    return this.httpRequest.get(this.stateUrlsu);
  }
  addSupplierModel(supplierModel: supplierModel): Observable<any> {
    return this.httpRequest.post(this.stateUrlsu, supplierModel);
  }
  deleteSupplier(id: number): Observable<number> {
    return this.httpRequest.delete(this.stateUrlsu + '/' + id);
  }
  updateSupplier(supplierModel: supplierModel, id: number): Observable<any> {
    return this.httpRequest.put(this.stateUrlsu + '/' + id, supplierModel);
  }
}
