import { T } from '@angular/cdk/keycodes';
import { Location, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeSectionFieldsDetailsService, FileDownload, FileFieldsId } from 'src/app/core/services/employee-section-fields-details-service';
import { EmployeeSectionService } from 'src/app/core/services/employee-section-service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { EmployeeSectionFieldsDetailsModel } from 'src/app/shared/models/employee-fields-edit-component';
import { EmployeeSectionFieldsModel, FormFields } from 'src/app/shared/models/employee-section-fields-model';
import { EmployeeSectionDetails } from 'src/app/shared/models/employee-section-model';
import { AppConfigService } from '../../configs/app-config.service';
import { EmployeeDocumentService } from '../../core/services/employee-document.service';
import { EmployeeService } from '../../core/services/employee.service';
import { JobPositionService } from '../../core/services/job-position.service';
import { UserService } from '../../core/services/user.service';
import { CommonService, compareFn } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { MessageService } from '../../shared/factory/message.service';
import { EmployeeDocumentFormBuilder, EmployeeDocumentModel } from '../../shared/models/employee-documents-model';
import { EmployeeFormBuilder, EmployeeModel } from '../../shared/models/employee-model';
import { ConfirmationDialogComponent, ProfilePictureDialogComponent } from '../../shared/popups/popups';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.css']
})


export class EmployeeEditComponent implements OnInit {

  @Input() index:number;
  employee: EmployeeFormBuilder = new EmployeeFormBuilder();
  employeeModel: EmployeeModel = this.employee.employeeModel;
  employeeForm: FormGroup = this.employee.employeeForm;
  employeeDocument: EmployeeDocumentFormBuilder = new EmployeeDocumentFormBuilder();
  employeeDocumentModel: EmployeeDocumentModel = this.employeeDocument.employeeDocumentModel;
  employeeDocumentForm: FormGroup = this.employeeDocument.employeeDocumentForm;

  compareFn = compareFn;
  countries = [];
  jobTitles = fetchCachedData('jobTitles');
  departments = fetchCachedData('departments');
  recruiters = fetchCachedData('recruiters');
  jobPositions = [];
  documentNames = [];
  managerEmps = [];
  selectedFileName = "";
  agreementLetter: any = null;
  document: any = null;
  auth_token = sessionStorage.getItem('x-auth-token');
  profileSelected: boolean = false;
  img = './assets/images/avatar.jpg';
  list: Array<EmployeeDocumentModel>;
  displayedColumns: string[] = ['id', 'name', 'download', 'action'];
  dataSource: MatTableDataSource<EmployeeDocumentModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  employeeId = null;
  dynamicFormFields = []
  tabindex;
  formSectionFields = [];
  fieldControls = [];
  sectionId: number;
  // sectionFieldsId:number;
  fieldsId: number;
  fields: JSON;
  empId: number;
  filePath;
  fileFieldsId: FileFieldsId = {};
  myMap = new Map<string, Object>();
  fileMap = new Map<number, Object>();
  editFileList = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private employeeService: EmployeeService,
    private employeeDocumentService: EmployeeDocumentService,
    private _commonServices: CommonService,
    private userService: UserService,
    private jobPositionService: JobPositionService,
    public _configUrls: AppConfigService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private routes: Router,
    private employeeSectionService: EmployeeSectionService,
    private employeeSectionFieldsDetailsService: EmployeeSectionFieldsDetailsService,
    public location: Location,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private configs:AppConfigService

  ) {
  }
  ngOnInit() {



    this._commonServices.fetchCountries().subscribe(res => {
      this.countries = res.countries;
    });
    this.fetchAllEmployeeSection();
    this.employeeModel.id = parseInt(this.route.snapshot.paramMap.get('employeeId'), 0);
    this.employeeDocumentModel.empId = parseInt(this.route.snapshot.paramMap.get('employeeId'), 0);
    this.fetchEmployee(this.employeeModel.id);
    this.fetchJobPositions();
    this.fetchManagerEmps();
    this.fetchEmpProfilePic(this.employeeModel.id);
    this.fetchDocumentType(this.employeeDocumentModel.empId)
    this.dataSource = new MatTableDataSource<EmployeeDocumentModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchAllEmployeeSection() {
    this.employeeSectionService.getAllEmployeeSectionAndFields().subscribe((res: EmployeeSectionDetails[]) => {
      res.forEach(element => {
        const employeeSection: EmployeeSectionFieldsModel = {};
        const employeeSectionFieldsMaster: FormFields[] = [];
        employeeSection.id = element.id;
        employeeSection.sectionName = element.sectionName;
        employeeSection.sectionDescription = element.sectionDescription;
        element.fieldDefinitions.forEach(v => {

          const employeeSectionFields: FormFields = {};

          employeeSectionFields.id = v.id;
          employeeSectionFields.labelName = v.labelName;
          employeeSectionFields.fieldDescription = v.fieldDescription;
          employeeSectionFields.fieldType = v.fieldType;

          employeeSectionFields.placeholder = v.attributes.placeholder;
          employeeSectionFields.isRequired = v.attributes.isRequired;
          employeeSectionFields.values = v.attributes.values;

          employeeSectionFieldsMaster.push(employeeSectionFields);
        });

        employeeSection.formFields = employeeSectionFieldsMaster;
        this.formSectionFields.push(employeeSection);
        this.formSectionFields.filter(
          () => {
            this.dynamicFormFields.push(this.formBuilder.group({}))
          }
        )
      });
    });
  }

  fetchEmpProfilePic(employeeId) {
    this.employeeService.fetchEmpProfilePic(employeeId).subscribe((res: any) => {
      if (res.isProfileImageExist) {
        this.profileSelected = true;
        this.img = res.imageBase64Encoded;
      }
    });
  }

  openProfilePictureDialog(employeeId) {
    const dialogRef1 = this.dialog.open(ProfilePictureDialogComponent, {
      data: { employeeId: employeeId, profileSelected: this.profileSelected },
      width: '700px'
    });

    dialogRef1.afterClosed().subscribe(result => {
      if (result.data == 'uploaded') {
        this.img = result.img.base64;
      }
    });
  }

  fetchEmployee(employeeId) {
    this.employeeService.fetchEmployee(employeeId).subscribe((res: EmployeeModel) => {
      this.employee.setModelVals(res);
    });
  }
  fetchEmployeeDocuments(employeeId) {
    this.employeeDocumentService.fetchEmployeeDocuments(employeeId).subscribe((res: EmployeeDocumentModel) => {
      this.employeeDocument.setModelVals(res);
    });
  }

  fetchManagerEmps() {
    this.userService.fetchEmployeesLite().subscribe(res => {
      this.managerEmps = res;
    });
  }

  ondatechange() {
    var dateOfLeaving = this.employeeForm.controls.dateOfLeaving.value;
    if (dateOfLeaving != null) {
      this.employeeModel.isActive = false;
    }
  }

  fetchJobPositions() {
    this.jobPositionService.fetchJobPositions().subscribe(res => {
      this.jobPositions = res;
    });
  }
  fetchDocumentType(employeeId) {
    this.employeeDocumentService.fetchDocumentType(employeeId).subscribe((res: EmployeeDocumentModel[]) => {
      this.documentNames = res;
      this.dataSource = new MatTableDataSource<EmployeeDocumentModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  AddFileInput($event) {
    var regex = /(.*?)\.(docx|doc|pdf)$/;
    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.document = $event.srcElement.files[0];
    }
  }

  downloadEmployeeDocument(id, empId) {
    this.employeeDocumentService.downloadEmployeeDocument(id, empId);
  }

  updateEmployee() {
    let formData: FormData = new FormData();
    if (this.agreementLetter !== null) formData.append('agreementFile', this.agreementLetter, this.agreementLetter.name);
    let employeeDTO = new Blob([JSON.stringify(this.employeeModel)], {
      type: 'application/json'
    });
    formData.append('employeeDTO', employeeDTO);
    this.employeeService.updateEmployee(formData).subscribe((res) => {
      this.messageService.display('Employee update is successfull! Employee ID : ' + this.employeeModel.id, 'OK', 'success');
      this.router.navigate(['/employees']);
    });
  }

  addEmployeeDocument() {
    let formData: FormData = new FormData();
    if (this.document !== null) formData.append('documentFile', this.document, this.document.name);
    let employeeDocumentDTO = new Blob([JSON.stringify(this.employeeDocumentModel)], {
      type: 'application/json'
    });
    formData.append('employeeDocumentDTO', employeeDocumentDTO);
    this.employeeDocumentService.addEmployeeDocument(formData).subscribe(() => {
      this.fetchDocumentType(this.employeeDocumentModel.empId);
      this.employeeDocumentForm.reset();
    });
  }

  openDialog(ID, empId): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: "Are u sure , you want to delete this document?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.employeeId = empId;
        this.deleteDocumentOfEmp(ID);
      }
    });
  }

  deleteDocumentOfEmp(Id) {
    this.employeeDocumentService.deleteDocumentByEmp(Id).subscribe((res: any) => {
      this.messageService.display('File is successfully deleted !', 'OK', 'success');
      this.fetchDocumentType(this.employeeId);
    });
  }

  onStateChange(event, fieldId, sectionId) {
    let formData: FormData = new FormData();
    var regex = /(.*?)\.(docx|doc|pdf)$/;
    if ((event.srcElement.files.length > 0) && (regex.test(event.srcElement.files[0].name))) {
      this.selectedFileName = event.srcElement.files[0].name;
      this.document = event.srcElement.files[0];
    }

    let fileList: File = event.target.files[0];
    if (fileList) {
      let file: File = fileList;
      formData.append('file', file);
    }

    const addEmployeeSectionFields: EmployeeSectionFieldsDetailsModel = {};
    this.empId = +this.route.snapshot.params['employeeId'];
    addEmployeeSectionFields.fields = this.dynamicFormFields[this.tabindex].value;
    addEmployeeSectionFields.sectionId = sectionId;


    this.fileFieldsId.empId = this.empId;
    this.fileFieldsId.sectionId = addEmployeeSectionFields.sectionId;
    this.fileFieldsId.fieldId = fieldId;

    formData.append('pathData', JSON.stringify(this.fileFieldsId))
    this.employeeSectionFieldsDetailsService.addFilePath(formData).subscribe(res=>{
      this.fileMap.set(fieldId, res);
    })
  }

  createGroup(i) {
    this.dynamicFormFields[i].reset();
    this.formSectionFields[i].formFields.filter(res => {
      if (res.isRequired == 'Yes') {
        this.dynamicFormFields[i].addControl(res.id, this.formBuilder.control('', Validators.required))
      }
      else {
        this.dynamicFormFields[i].addControl(res.id
          , this.formBuilder.control(''))
      }
    });
    this.fieldControls = Object.values(this.dynamicFormFields[i]?.controls)
  }
  submitForm(id) {
    this.empId = this.route.snapshot.params['employeeId'];
    const addEmployeeSectionFields: EmployeeSectionFieldsDetailsModel = {};
    addEmployeeSectionFields.fields = this.dynamicFormFields[this.tabindex].value;
    addEmployeeSectionFields.employeeId = this.empId;
    addEmployeeSectionFields.sectionId = id;
    Object.keys(addEmployeeSectionFields.fields).forEach(key => {
      if (this.fileMap.has(parseInt(key))) {
          addEmployeeSectionFields.fields[key] = this.fileMap.get(parseInt(key));
      }
    })
    this.employeeSectionFieldsDetailsService.addEmployeeSection(addEmployeeSectionFields).subscribe(data => {
      this.messageService.display('Section Fields added successfully! Section ID : ' + data, 'OK', 'success');
      this.loaderService.updateInboxAction(true);
    });
    this.employeeSectionEvent(this.index);
  }


  employeeSectionEvent(event) {
    this.index=event;
    let events:number;
    this.editFileList = [];
    this.empId = this.route.snapshot.params['employeeId'];
    let sectionFieldsId: number;
    events = event - 2;
    for (let i = 0; i < this.formSectionFields.length; i++) {
      if (this.formSectionFields[i].id == this.formSectionFields[events].id) {
        sectionFieldsId = this.formSectionFields[i].id
      }
    }
    this.employeeSectionFieldsDetailsService.getAllSectionDetails(this.empId, sectionFieldsId).subscribe(data => {
      Object.keys(data['fields']).forEach(Key => this.myMap.set(Key, data['fields'][Key]))
      console.log(this.myMap);
      let index = 0;
      this.myMap.forEach((value: any, key: string) => {
        this.editFileList[index] = null;
        if (typeof value === 'object') {
          if(value.fileName!==undefined){
            let fileUrl = { Name: value.fileName};
            this.editFileList[index] = fileUrl;
            this.fileMap.set(parseInt(key),value);
          }else{
            this.dynamicFormFields[events].controls[key].setValue(value);
          }
        } else {
          this.dynamicFormFields[events].controls[key].setValue(value);
        }
        index++;
      });
      this.myMap.clear();
    });
  }

  fileUrl(sectionId,fieldsId,id){
    this.empId=+this.route.snapshot.params['employeeId'];
    let fileDownload:FileDownload={};
    fileDownload.fileName=this.editFileList[id].Name;
    let fileName: string= this.editFileList[id].Name;
    const a = document.createElement('a');
    a.href = `${this.configs.tempApiPath}api/v1/employee-section-details/download/${this.empId}/${sectionId}/${fieldsId}/${fileName}`;
    a.target="_blank";
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove()
  }

  activityData(event) {
    for (let i = 0; i < this.formSectionFields.length; i++) {
      if (this.formSectionFields[i].sectionName == event.textLabel) {
        this.tabindex = i;
        this.createGroup(i);
      }
    }
  }
  
 
  deactiveEmployee() {

    this.employeeService.deaciveEmployee(this.employeeModel).subscribe((res) => {
      this.messageService.display('Employee deactive  successfull! Employee ID : ' + this.employeeModel.id, 'OK', 'success');
      this.router.navigate(['/employees']);
    });

  }
}
