import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierActivityService {

  private assetActivityURL = "api/v1/activity/filter-specific-activity";

  constructor(private httpRequest: HttpRequestService) { }

  getSupplierActivities(activityFilters,pageNo: number, pageSize: number) {
    return this.httpRequest.get(this.assetActivityURL + '?page=' + pageNo + '&size=' + pageSize + '&activityFilters=' + activityFilters);
  }
}
