import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators, dateTimeFormatters } from '../factory/common.service';

export interface EmployeeModel {
    id: number,
    firstName: string,
    employeeCode: string,
    lastName: string,
    gender : string,
    dateOfBirth: any,
    contact: {
        primaryPhoneNumber: string,
        secondaryPhoneNumber: string,
        email: string
    },
    baseAddres: {
        address1: string,
        address2: string,
        country: string,
        state: string,
        city: string,
        zipCode: string
    },
    jobTitleId: number,
    jobPositionId: number,
    departmentId: number,
    managerEmpId: number,
    recruiterId: number,
    candidateId: number,
    dateOfJoining: any,
    dateOfLeaving: any,
    isActive: boolean
    
}
export class EmployeeFormBuilder {
    employeeModel: EmployeeModel = {
        id: null,
        firstName: '',
        lastName: '',
        employeeCode:'',
        gender: '',
        dateOfBirth: null,
        contact: {
            primaryPhoneNumber: '',
            secondaryPhoneNumber: null,
            email: ''
        },
        baseAddres: {
            address1: '',
            address2: '',
            country: '',
            state: '',
            city: '',
            zipCode: ''
        },
        jobTitleId: null,
        jobPositionId: null,
        departmentId: null,
        managerEmpId: null,
        recruiterId: null,
        candidateId: null,
        dateOfJoining: null,
        dateOfLeaving: null,
        isActive: true,
     
    };
    employeeForm: FormGroup = new FormGroup({
        firstName: new FormControl(this.employeeModel.firstName, [Validators.required]),
        lastName: new FormControl(this.employeeModel.lastName, [Validators.required]),
        primaryPhoneNumber: new FormControl(this.employeeModel.contact.primaryPhoneNumber, [Validators.required, customValidators.numberValidator()]),
        secondaryPhoneNumber: new FormControl(this.employeeModel.contact.secondaryPhoneNumber, [customValidators.numberValidator()]),
        email: new FormControl(this.employeeModel.contact.email, [Validators.required, customValidators.emailValidator()]),
        gender : new FormControl(this.employeeModel.gender, [Validators.required]),
        dateOfBirth : new FormControl(this.employeeModel.dateOfBirth, [Validators.required]),
        address1: new FormControl(this.employeeModel.baseAddres.address1, [Validators.required]),
        address2: new FormControl(this.employeeModel.baseAddres.address2),
        country: new FormControl(this.employeeModel.baseAddres.country, [Validators.required]),
        state: new FormControl(this.employeeModel.baseAddres.state, [Validators.required]),
        city: new FormControl(this.employeeModel.baseAddres.city, [Validators.required]),
        zipCode: new FormControl(this.employeeModel.baseAddres.zipCode, [Validators.required, customValidators.numberValidator()]),
        jobTitleId: new FormControl(this.employeeModel.jobTitleId, [Validators.required]),
        jobPositionId: new FormControl(this.employeeModel.jobPositionId, [Validators.required]),
        departmentId: new FormControl(this.employeeModel.departmentId, [Validators.required]),
        managerEmpId: new FormControl(this.employeeModel.managerEmpId, [Validators.required]),
        recruiterId: new FormControl(this.employeeModel.recruiterId),
        dateOfJoining: new FormControl((this.employeeModel.dateOfJoining), [Validators.required]),
        dateOfLeaving: new FormControl((this.employeeModel.dateOfLeaving)),
        isActive: new FormControl(this.employeeModel.isActive),
        employeeCode: new FormControl(this.employeeModel.employeeCode)

    });
    constructor() {
        this.employeeForm.valueChanges.subscribe(val => {
            this.employeeModel.firstName = val.firstName;
            this.employeeModel.lastName = val.lastName;
            this.employeeModel.contact.primaryPhoneNumber = val.primaryPhoneNumber;
            this.employeeModel.contact.secondaryPhoneNumber = val.secondaryPhoneNumber;
            this.employeeModel.contact.email = val.email;
            this.employeeModel.gender = val.gender;
            this.employeeModel.dateOfBirth = (val.dateOfBirth==null) ? val.dateOfBirth : dateTimeFormatters.formatDate(val.dateOfBirth);
            this.employeeModel.baseAddres.address1 = val.address1;
            this.employeeModel.baseAddres.address2 = val.address2;
            this.employeeModel.baseAddres.city = val.city;
            this.employeeModel.baseAddres.country = val.country;
            this.employeeModel.baseAddres.state = val.state;
            this.employeeModel.baseAddres.zipCode = val.zipCode;
            this.employeeModel.jobTitleId = val.jobTitleId;
            this.employeeModel.jobPositionId = val.jobPositionId;
            this.employeeModel.departmentId = val.departmentId;
            this.employeeModel.managerEmpId = val.managerEmpId;
            this.employeeModel.recruiterId = val.recruiterId;
            this.employeeModel.dateOfJoining = (val.dateOfJoining==null) ? val.dateOfJoining : dateTimeFormatters.formatDate( val.dateOfJoining);
            this.employeeModel.dateOfLeaving = (val.dateOfLeaving==null) ? val.dateOfLeaving : dateTimeFormatters.formatDate( val.dateOfLeaving);
            this.employeeModel.isActive = val.isActive;
            this.employeeModel.employeeCode = val.employeeCode;
         
        });
    }
    setModelVals(res: EmployeeModel) {
        this.employeeForm.controls.firstName.setValue(res.firstName);
        this.employeeForm.controls.lastName.setValue(res.lastName);

        if(res.contact != null)
        {
            this.employeeForm.controls.primaryPhoneNumber.setValue(res.contact.primaryPhoneNumber);
            this.employeeForm.controls.secondaryPhoneNumber.setValue(res.contact.secondaryPhoneNumber);
            this.employeeForm.controls.email.setValue(res.contact.email);
        }
        this.employeeForm.controls.dateOfBirth.setValue(res.dateOfBirth);
        this.employeeForm.controls.gender.setValue(res.gender);
        if(res.baseAddres != null)
        {
            this.employeeForm.controls.address1.setValue(res.baseAddres.address1);
            this.employeeForm.controls.address2.setValue(res.baseAddres.address2);
            this.employeeForm.controls.country.setValue(res.baseAddres.country);
            this.employeeForm.controls.state.setValue(res.baseAddres.state);
            this.employeeForm.controls.city.setValue(res.baseAddres.city);
            this.employeeForm.controls.zipCode.setValue(res.baseAddres.zipCode);
        }
        this.employeeForm.controls.jobTitleId.setValue(res.jobTitleId);
        this.employeeForm.controls.jobPositionId.setValue(res.jobPositionId);
        this.employeeForm.controls.departmentId.setValue(res.departmentId);
        this.employeeForm.controls.managerEmpId.setValue(res.managerEmpId);
        this.employeeForm.controls.recruiterId.setValue(res.recruiterId);
        this.employeeForm.controls.dateOfJoining.setValue(res.dateOfJoining);
        this.employeeForm.controls.dateOfLeaving.setValue(res.dateOfLeaving);
        this.employeeForm.controls.employeeCode.setValue(res.employeeCode);
        this.employeeModel.candidateId = res.candidateId;
        this.employeeModel.isActive = res.isActive;
     
    }
}