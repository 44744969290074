import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-input-control',
  templateUrl: './form-input-control.component.html',
  styleUrls: ['./form-input-control.component.css']
})
export class FormInputControlComponent implements OnInit {

  @Input() control: FormControl;
  @Input() maxlength: any = 1000;
  @Input() minlength: any = 0;
  @Input() label: any;
  @Input() errorName: any;
  @Input() prefix: any;
  @Input() class: any = 'inputGape';
  @Input() type: any;
  @Input() placeholder: any;
  @Input() help: any;
  @Input() helpText: any;
  @Input() isTextArea: any = false;
  
    constructor() { }
  
    ngOnInit(): void {
    }
  
    validator(control) {
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      } else {
        return false;
      }
    }
  
  }
  
