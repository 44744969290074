import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EmployeeService } from '../../core/services/employee.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip'
import { dateTimeFormatters, fetchDisplayData, customValidators } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { AppConfigService } from '../../configs';
import { MatSidenav } from '@angular/material/sidenav';
import { EmployeeModel } from '../../shared/models/employee-model';
import { MessageService } from '../../shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpService } from '../../core/services/help.service';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { EmployeeSectionFieldsDetailsService } from 'src/app/core/services/employee-section-fields-details-service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
  // providers: [
  //   {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}
  // ],
})
export class EmployeeComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'jobTitle', 'jobPosition', 'department', 'email', 'phone', 'action'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  employeeCount = 0;
  dateTimeFormatters = dateTimeFormatters;
  jobTitles = fetchCachedData('jobTitles');
  jobPositions = fetchCachedData('jobPositions');
  departments = fetchCachedData('departments');
  fetchDisplayData = fetchDisplayData;
  sessionStorage = sessionStorage;
  auth_token = sessionStorage.getItem('x-auth-token');
  employees = [];
  img = './assets/images/avatar.jpg';
  isFetchAllActive: boolean = false; 
  showAllEmployees = false; 
  currentPage: number = 0;
  searchFormData = {
    name: '',
    positionId: '',
    titleId: '',
    departmentId: '',
    email: '',
    phoneNumber: '',
    fromDate: null,
    toDate: null,
    fromJoiningDate: null,
    toJoiningDate: null,
    fromLeavingDate: null,
    toLeavingDate: null
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    departmentId: new FormControl(this.searchFormData.departmentId),
    email: new FormControl(this.searchFormData.email, [customValidators.emailValidator()]),
    phoneNumber: new FormControl(this.searchFormData.phoneNumber, [customValidators.numberValidator()]),
    fromDate: new FormControl(this.searchFormData.fromDate),
    toDate: new FormControl(this.searchFormData.toDate),
    fromJoiningDate: new FormControl(this.searchFormData.fromJoiningDate),
    toJoiningDate: new FormControl(this.searchFormData.toJoiningDate),
    fromLeavingDate: new FormControl(this.searchFormData.fromLeavingDate),
    toLeavingDate: new FormControl(this.searchFormData.toLeavingDate)
  });
  searched = false;
  expanded = true;
  isActive: boolean ;
  constructor(
    private employeeService: EmployeeService,
    public _configUrls: AppConfigService,
    private eleRef: ElementRef,
    private messageService: MessageService,
    private peopleEmployeeSectionService:EmployeeSectionFieldsDetailsService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private scrollDispatcher: ScrollDispatcher,

  ) { }

  ngOnInit() {
    this.fetchEmployeesCount();
    this.fetchAllActiveEmployees(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
    // this.scrollDispatcher.scrolled().subscribe(x => {
    //     if (this.totalCount > this.employees.length) {
    //         this.pageNo++;
    //         this.onScroll();
    //     }
    //     else{
    //         this.pageNo = 0;
    //     }
    // });
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.searched ? this.searchEmployees() : this.fetchAllActiveEmployees(this.pageNo, this.pageSize);
    });
  }

  fetchEmpProfilePic(employee) {
    this.employeeService.fetchEmpProfilePic(employee.id).subscribe((res: any) => {
      if (res.isProfileImageExist) {
        employee['img'] = res.imageBase64Encoded;
      }
    });
  }

//   onScroll() {
//      if ((this.pageNo != 0) && (this.totalCount > this.employees.length)) {
//       this.searched ? (this.pageNo++ , this.searchEmployees()) : this.fetchEmployees(this.pageNo, this.pageSize);
//     }
//  }

  fetchEmployeesCount() {
    this.peopleEmployeeSectionService.fetchEmployeesCount().subscribe((res: number) => {
      this.totalCount = res;
    });
  }
  fetchAllActiveEmployees(pageNo: number, pageSize: number) {
    this.peopleEmployeeSectionService.fetchAllActiveEmployees(pageNo, pageSize).subscribe((res: any) => {
      if (res && res.length > 0) {
        for (const employee of res) {
          this.employees.push({
            ...employee,
            source: 'active'
          });
        }
        this.dataSource = new MatTableDataSource<any>(this.employees);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.employeeCount = this.employees.length;
      }
    });
  }
fetchAllEmployees(event: any) {
  const pageSize = 10;
  if (event.checked) {
    this.peopleEmployeeSectionService.fetchEmployees(this.currentPage, pageSize).subscribe((newEmployees: EmployeeModel[]) => {
      if (newEmployees.length > 0) {
        this.employees = this.currentPage == 0 ? newEmployees : this.employees.concat(newEmployees);
        this.dataSource.data = this.employees;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      this.currentPage++;
    });
  } else {
    this.employees = [];
    this.dataSource.data = [];
    this.currentPage = 0;
    this.pageNo = 0;
    this.fetchAllActiveEmployees(this.currentPage, pageSize);
  }
}

  refreshEmployees() {
    this.employees = [];
    this.pageNo = 0;
    this.pageSize = 10;
    this.showAllEmployees = false; 
    this.dataSource = new MatTableDataSource<any>(this.employees);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.searched ? this.searchEmployees() : this.fetchAllActiveEmployees(this.pageNo, this.pageSize);
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchEmployees() {
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromDate ? (this.searchFormData.fromDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromDate)) : null;
    this.searchFormData.toDate ? (this.searchFormData.toDate = this.dateTimeFormatters.formatDate(this.searchFormData.toDate)) : null;
    this.searchFormData.fromJoiningDate ? (this.searchFormData.fromJoiningDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromJoiningDate)) : null;
    this.searchFormData.toJoiningDate ? (this.searchFormData.toJoiningDate = this.dateTimeFormatters.formatDate(this.searchFormData.toJoiningDate)) : null;
    this.searchFormData.fromLeavingDate ? (this.searchFormData.fromLeavingDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromLeavingDate)) : null;
    this.searchFormData.toLeavingDate ? (this.searchFormData.toLeavingDate = this.dateTimeFormatters.formatDate(this.searchFormData.toLeavingDate)) : null;
    let obj = {};
    this.searchFormData.email ? (obj['email'] = this.searchFormData.email) : null;
    this.searchFormData.departmentId ? (obj['departmentId'] = this.searchFormData.departmentId) : null;
    this.searchFormData.fromDate ? (obj['fromDate'] = this.searchFormData.fromDate) : null;
    this.searchFormData.toDate ? (obj['toDate'] = this.searchFormData.toDate) : null;
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;
    this.searchFormData.phoneNumber ? (obj['phoneNumber'] = this.searchFormData.phoneNumber) : null;
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    this.searchFormData.fromJoiningDate ? (obj['fromJoiningDate'] = this.searchFormData.fromJoiningDate) : null;
    this.searchFormData.toJoiningDate ? (obj['toJoiningDate'] = this.searchFormData.toJoiningDate) : null;
    this.searchFormData.fromLeavingDate ? (obj['fromLeavingDate'] = this.searchFormData.fromLeavingDate) : null;
    this.searchFormData.toLeavingDate ? (obj['toLeavingDate'] = this.searchFormData.toLeavingDate) : null;
    obj != {} ? this.employeeService.fetchEmployeeSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
      (res > 0) ? this.employeeService.fetchEmployeeSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
        this.employees = [];
        for (var i = 0; i < resp.length; i++) {
          this.employees.push(resp[i]);
          this.fetchEmpProfilePic(this.employees[i]);
        }
        this.searched = true;
        this.closeSidenav();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.email ? (canBeSearched = true) : null;
    this.searchFormData.departmentId ? (canBeSearched = true) : null;
    this.searchFormData.fromDate ? (canBeSearched = true) : null;
    this.searchFormData.toDate ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    this.searchFormData.phoneNumber ? (canBeSearched = true) : null;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    this.searchFormData.fromJoiningDate ? (canBeSearched = true) : null;
    this.searchFormData.toJoiningDate ? (canBeSearched = true) : null;
    this.searchFormData.fromLeavingDate ? (canBeSearched = true) : null;
    this.searchFormData.toLeavingDate ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchEmployees();
    } else {
      this.employees = [];
      this.searched = false;
      this.pageNo = 0;
      this.fetchEmployeesCount();
      this.fetchAllActiveEmployees(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      positionId: '',
      titleId: '',
      departmentId: '',
      email: '',
      phoneNumber: '',
      fromDate: null,
      toDate: null,
      fromJoiningDate: null,
      toJoiningDate: null,
      fromLeavingDate: null,
      toLeavingDate: null
    });
    this.searchFormData = this.searchFormFields.value;
    this.searchFormData.fromDate ? (this.searchFormData.fromDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromDate)) : null;
    this.searchFormData.toDate ? (this.searchFormData.toDate = this.dateTimeFormatters.formatDate(this.searchFormData.toDate)) : null;
    this.searchFormData.fromJoiningDate ? (this.searchFormData.fromJoiningDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromJoiningDate)) : null;
    this.searchFormData.toJoiningDate ? (this.searchFormData.toJoiningDate = this.dateTimeFormatters.formatDate(this.searchFormData.toJoiningDate)) : null;
    this.searchFormData.fromLeavingDate ? (this.searchFormData.fromLeavingDate = this.dateTimeFormatters.formatDate(this.searchFormData.fromLeavingDate)) : null;
    this.searchFormData.toLeavingDate ? (this.searchFormData.toLeavingDate = this.dateTimeFormatters.formatDate(this.searchFormData.toLeavingDate)) : null;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.employees = [];
      this.fetchEmployeesCount();
      this.fetchAllActiveEmployees(this.pageNo, this.pageSize);
    }
  }

  findHelpUrlPage(){
    const currentPage = "/#_6_2_manage_employee";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  loadMoreEmployeeData(){
    this.pageNo++;
    if (!this.showAllEmployees) {
      this.fetchAllActiveEmployees(this.pageNo, 10);
    } else
    this.fetchAllEmployees({ checked: true, pageNo: this.pageNo });
    }
}
