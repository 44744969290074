import { Component, ElementRef, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AssetManagementService } from 'src/app/core/services/asset-management.service';
import { AssetManagement } from 'src/app/shared/models/asset-management';
// import { MatSort } from '@angular/material/sort';
import { ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddFieldsComponent, AssetReqApproversComponent, ViewAssetDetailsComponent } from 'src/app/shared/popups/popups';
import { EmployeeSectionFieldsDetailsService } from 'src/app/core/services/employee-section-fields-details-service';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/shared/factory/message.service';
import { O } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.css']
})
export class AssetManagementComponent implements OnInit {
  title = 'custom-search-filter-example';
  searchedKeyword: string;
  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'type', 'assetTag', 'model', 'supplierName', 'orderNo', 'status', 'employee', 'view'];
  dataSource: MatTableDataSource<AssetManagement>;
  @ViewChild(MatSort) sort: MatSort;
  //@ViewChild('sort') sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize: number = 100;
  pageNo: number = 0;
  employees = [];
  approvers = [];
  totalCount = 0;

  searchAssetData = {
    name: '',
    assetTag: ''
  }
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchAssetData.name),
    assetTag: new FormControl(this.searchAssetData.assetTag)
  });
  searched = false;
  expanded = true;

  constructor(private assetManageService: AssetManagementService,
    private messageService: MessageService,
    private employeeSectionFieldsDetailsService: EmployeeSectionFieldsDetailsService,
    private eleRef: ElementRef, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllAssetlist();
    this.getAllEmployees();
    this.getApprovers();
    this.dataSource = new MatTableDataSource<AssetManagement>([]);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllAssetlist() {

    this.assetManageService.getAllAssetlist().subscribe((res: AssetManagement[]) => {
      this.dataSource = new MatTableDataSource<AssetManagement>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }

  getAllEmployees() {
    // EmployeeSectionFieldsDetailsService.fetchAllEmployee();
    this.employeeSectionFieldsDetailsService.fetchActiveEmployees().subscribe((res: any) => {
      this.employees = res;
    });
  }

  getApprovers() {
    this.employeeSectionFieldsDetailsService.fetchApprovers().subscribe((res: any) => {
      this.approvers = res;
    });
  }

  // deleteAsset(id:BigInteger){
  //   this.assetManageService.deleteAsset(id).subscribe(data=>{
  //     console.log(data);
  //     this.getAllAssetlist();
  //   },
  //   error =>console.log(error)
  //   )
  // }
  deleteAsset(id: number) {
    this.assetManageService.deleteAsset(id).subscribe((res: AssetManagement[]) => {
      this.dataSource = new MatTableDataSource<AssetManagement>(res);

    });
  }

  findHelpUrlPage() {

  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }
  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  openOfferDialog(row) {
    const dialogRef = this.dialog.open(ViewAssetDetailsComponent, {
      disableClose: false,
      width: '80%',
      data: { dataSource: row, isEdit: false ,id:row?.id}
    });
  }

  allocation(row, id, action) {
    console.log(action);
    const dialogRef = this.dialog.open(AssetReqApproversComponent, {
      disableClose: false,
      width: '50%',
      data: { dataSource: row, action: action, employees: this.employees, approvers: this.approvers }

    });

  }
  assetSearch() {
    this.searchAssetData = this.searchFormFields.value;
    let obj = {};
    this.searchAssetData.name ? (obj['name'] = this.searchAssetData.name) : null;
    this.searchAssetData.assetTag ? (obj['assetTag'] = this.searchAssetData.assetTag) : null;
    obj != {} ? this.assetManageService.fetchAssetSearch(obj).subscribe(Response => {
      this.dataSource = new MatTableDataSource<AssetManagement>(Response);
      this.dataSource.sort = this.sort;
      this.searched = true;
      this.closeSidenav();
    }) : this.messageService.display('No records found!', 'OK', 'warn');


  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchAssetData.name ? (canBeSearched = true) : null;
    this.searchAssetData.assetTag ? (canBeSearched = true) : null;


    if (canBeSearched) {
      this.assetSearch();
    } else {
      this.searched = false;
      this.pageNo = 0;
      this.getAllAssetlist();

    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      assetTag: '',

    });
    this.searchAssetData = this.searchFormFields.value;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.getAllAssetlist();
    }
  }

  filterData(event: Event) {
    const filtervalue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtervalue;
  }
}

