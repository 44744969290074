import { FormGroup, FormControl, Validators } from '@angular/forms';
export interface BoardingModel {

    companyName: string;
    departmentName: string;
    timeZone: any;
    moduleName: string;

}
export class BoardingFormBuilder {
    boardingModel: BoardingModel = {
        companyName: '',
        departmentName: '',
        timeZone: null,
        moduleName: ''
    };
    boardingForm: FormGroup = new FormGroup({
        companyName: new FormControl(this.boardingModel.companyName),
        departmentName: new FormControl(this.boardingModel.departmentName),
        timeZone: new FormControl(this.boardingModel.timeZone),
    });
    constructor() {
        this.boardingForm.valueChanges.subscribe(val => {
            this.boardingModel.companyName = val.companyName;
            this.boardingModel.departmentName = val.departmentName;
            this.boardingModel.timeZone = val.timeZone;
        });
    }

}
