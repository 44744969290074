import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { OrganizationModel } from '../../shared/models/organization-model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private stateUrl = 'api/v1/organization';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchOrganization(): Observable<OrganizationModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  updateOrganization(organizationModel: OrganizationModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, organizationModel);
  }
}
