import { FormControl } from "@angular/forms";

export class supplierModel {
    id?: number;
    supplierName?: string;
    contactNumber?: bigint;
  
}
export class SupplierFormBuilder {
    id = new FormControl();
    supplierName = new FormControl('');
    contactNumber = new FormControl('');
   
 }