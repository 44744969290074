import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AssetAllocation } from 'src/app/shared/models/asset-allocation';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class AssetAllocationService {
  private requestUrl = 'api/vi/asset/action/request';
  private approverUrl = 'api/vi/asset/movement';

  
  constructor(private httpRequest: HttpRequestService,router:Router) { }

  getAllApproverList():Observable<AssetAllocation[]>{
    return this.httpRequest.get(this.requestUrl);
  }
  Approve(id):Observable<AssetAllocation[]>{
    return this.httpRequest.post(this.approverUrl+ "/approve?id=" + id, {});
  }
  reject(id):Observable<AssetAllocation[]>{
    return this.httpRequest.post(this.approverUrl+"/reject?id=" + id,{});
  }
}
