import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSOGatewayComponent } from './sso-gateway/sso-gateway.component';
import { MaterialModule } from '../shared/modules/material/material.module';
import { LoginService } from '../core/services/login.service';
import { SSOGatewayService } from '../core/services/sso-gateway.service';
import { AppRoutingModule } from '../app-routing.module';
import { HomeComponent } from './home/home.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { EmployeeDocumentComponent } from './employee-document/employee-document.component';
import { JobOfferComponent } from './job-offer/job-offer.component';
import { ProfileComponent } from './profile/profile.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { InterviewActionDialogComponent, CandidateOfferDialogComponent, EmployeeStartupDialogComponent,BoardingComponent, ConfirmationDialogComponent, ProfilePictureDialogComponent, ConfirmationBoxComponent, ViewAssetDetailsComponent, AssetReqApproversComponent,SupplierDataAddComponent,ViewAssetHistoryComponent} from '../shared/popups/popups';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MonthToYearPipe } from '../month-to-year.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CalendarModule } from 'angular-calendar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EmployeeSectionComponent } from './employee-section/employee-section.component';
import { AddEmployeeSectionComponent } from './add-employee-section/add-employee-section.component';
import { AddFieldsComponent } from '../shared/popups/popups';
import { FormInputControlComponent } from './form-input-control/form-input-control.component';
import { FormSelectControlComponent } from './form-select-control/form-select-control.component';
import { FormControlMessageComponent } from './form-control-message/form-control-message.component';
import { AssetManagementComponent } from './asset-management/asset-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoginComponent } from './login/login.component';
import { AddAssetManageComponent } from './add-asset-manage/add-asset-manage.component';
import { AssetAllocationComponent } from './asset-allocation/asset-allocation.component';
import { AasignAssetComponent } from './aasign-asset/aasign-asset.component';
import { PeopleActivityComponent } from './people-activity/people-activity.component';
import { UserNamePipe } from '../pipes/user-name.pipe';
import { AgoDatePipe } from '../pipes/ago-date.pipe';
import { AssetActivityComponent } from './asset-activity/asset-activity.component';
import { supplierModelComponent } from './add-supplier/add-supplier.component';
import { SupplierComponent } from './supplier/supplier.component';
import { SupplierActivityComponent } from './supplier-activity/supplier-activity.component';

import { AssetHistoryComponent } from '../shared/components/activity-history/asset-history.component';

@NgModule({
  imports: [ 
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalendarModule,
    InfiniteScrollModule,
    ImageCropperModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    LoginService,
    SSOGatewayService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: false } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } }
  ],
  declarations: [
    LoginComponent,
    SSOGatewayComponent,
    HomeComponent,
    EmployeeComponent,
    EmployeeEditComponent,
    EmployeeDocumentComponent,
    JobOfferComponent,
    MonthToYearPipe,
    InterviewActionDialogComponent,
    CandidateOfferDialogComponent,
    EmployeeStartupDialogComponent,
    ConfirmationDialogComponent,
    ProfileComponent,
    BoardingComponent,
    ProfilePictureDialogComponent,
    EmployeeSectionComponent,
    AddEmployeeSectionComponent,
    AddFieldsComponent,
    ConfirmationBoxComponent,
    FormInputControlComponent,
    FormSelectControlComponent,
    FormControlMessageComponent,
    AssetManagementComponent,
    AddAssetManageComponent,
    ViewAssetDetailsComponent,
    AssetAllocationComponent,
    AssetReqApproversComponent,
    SupplierDataAddComponent,
    ViewAssetHistoryComponent,
    AasignAssetComponent,
    PeopleActivityComponent,
    UserNamePipe,
    AgoDatePipe,
    AssetActivityComponent,
    supplierModelComponent,
    SupplierComponent,
    SupplierActivityComponent,
   
    AssetHistoryComponent
    
  ],
  entryComponents: [
    InterviewActionDialogComponent,
    CandidateOfferDialogComponent,
    EmployeeStartupDialogComponent,
    ConfirmationDialogComponent,
    BoardingComponent,
    ProfilePictureDialogComponent,
    AddFieldsComponent,
    ConfirmationBoxComponent,
    ViewAssetDetailsComponent,
    AssetReqApproversComponent,
    SupplierDataAddComponent,
    ViewAssetHistoryComponent
  ]
})
export class ComponentsModule { }
