import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { JobDescriptionModel } from '../../shared/models/job-description-model';

@Injectable({
  providedIn: 'root'
})
export class JobDescriptionService {

  private stateUrl = 'api/v1/job-description';

  constructor(private httpRequest: HttpRequestService) { }

  fetchJobDescriptions(): Observable<JobDescriptionModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchJobDescription(jobDescriptionId): Observable<JobDescriptionModel> {
    return this.httpRequest.get(this.stateUrl + '/' + jobDescriptionId);
  }

  updateJobDescription(jobDescriptionModel: JobDescriptionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, jobDescriptionModel);
  }

  addJobDescription(jobDescriptionModel: JobDescriptionModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, jobDescriptionModel);
  }
}
