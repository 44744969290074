import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {EmployeeDocumentModel, EmployeeDocumentFormBuilder} from '../../shared/models/employee-documents-model'
import {EmployeeDocumentService} from '../../core/services/employee-document.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { UserService } from '../../core/services/user.service';
import { JobPositionService } from '../../core/services/job-position.service';

import { compareFn, CommonService } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { AppConfigService } from '../../configs/app-config.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { ProfilePictureDialogComponent } from '../../shared/popups/popups';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-document',
  templateUrl: './employee-document.component.html',
  styleUrls: ['./employee-document.component.css']
})
export class EmployeeDocumentComponent implements OnInit {



  employeeDocument: EmployeeDocumentFormBuilder = new EmployeeDocumentFormBuilder();
  employeeDocumentModel: EmployeeDocumentModel = this.employeeDocument.employeeDocumentModel;
  employeeDocumentForm: FormGroup = this.employeeDocument.employeeDocumentForm;
  selectedFileName = "";
  document: any = null;

  constructor( private route: ActivatedRoute,
    public router: Router,
    private employeeDocumentService: EmployeeDocumentService,
    private _commonServices: CommonService,
    private userService: UserService,
    private jobPositionService: JobPositionService,
    public _configUrls: AppConfigService,
    private messageService: MessageService,
    public location: Location,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.employeeDocumentModel.empId = parseInt(this.route.snapshot.paramMap.get('employeeId'), 0);
   
  }


  onFileInput($event) {
    var regex = /(.*?)\.(docx|doc|pdf)$/;
    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.document = $event.srcElement.files[0];
    }
  }
  

  addEmployeeDocument() {
    let formData: FormData = new FormData();
    if (this. document!== null) formData.append('documentFile', this.document, this.document.name);
    let employeeDocumentDTO = new Blob([JSON.stringify(this.employeeDocumentModel)], {
      type: 'application/json'
    });
    formData.append('employeeDocumentDTO', employeeDocumentDTO);
    this.employeeDocumentService.addEmployeeDocument(formData).subscribe(() => {
     
      
      this.router.navigate(['/employees']);
    });
  }

}
