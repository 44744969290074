import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataCachingService } from '../../shared/factory/data-caching.service';
import { LoginService } from '../../core/services/login.service';
import { UserService } from '../../core/services/user.service';
import { LoaderService } from '../../shared/factory/loader.service';
import { AppConfigService } from '../../configs/app-config.service';
import { MatDialog } from '@angular/material/dialog';
import { SSOGatewayService } from '../../core/services/sso-gateway.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
//import { BoardingComponent } from '../../shared/popups/popups';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sideMenuFull = true;
  currentScreenWidth;
  
  configs = new AppConfigService();


  user = {
    name: '',
    emailOrUsername: '',
    boardingStatus: '',
    img: './assets/images/avatar.jpg'
  };

  isOpen = false;

  inboxAction = {
    inboxTotal: 0,
    leaveRequestApproval: 0
  };

  constructor(
    public router: Router,
    private _dataCachingService: DataCachingService,
    private loginService: LoginService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private loaderService: LoaderService,
    private ssoGatewayService:SSOGatewayService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.alterSideNav();
    window.addEventListener('resize', () => {
      if (this.currentScreenWidth !== window.innerWidth) {
        this.alterSideNav();
      }
    });
    this._dataCachingService.fetchAll();
    const temp = sessionStorage.getItem('loginData');
    console.log(sessionStorage.getItem('x-auth-token'));
    this.user['name'] = JSON.parse(temp).name;
    this.user['emailOrUsername'] = JSON.parse(temp).emailOrUsername;
    this.user['boardingStatus'] = JSON.parse(temp).boardingStatus;
    this.fetchEmpProfilePic(JSON.parse(temp).id);
    this.fetchInboxCounts();
    this.loaderService.inboxAction.subscribe((val: boolean) => {
      this.fetchInboxCounts();
    });

  //   if(this.user['boardingStatus'] != "COMPLETED") {
  //     setTimeout(() => {
  //      this.openDialog()
  //    });
  //  }
}
fetchEmpProfilePic(id) {
  this.employeeService.fetchEmpProfilePicByUserId(id).subscribe(res => {
    if (res.isProfileImageExist) {
      this.user['img'] = res.imageBase64Encoded;
    }
  });
}

  fetchInboxCounts() {
    this.userService.fetchInboxCounts().subscribe(res => {
      this.inboxAction = res;
      this.inboxAction.inboxTotal = res.leaveRequestApproval;
    });
  }

  logout() {
    this.loginService.logout().subscribe(res => {
      sessionStorage.clear();
      this.router.navigate(['/?logout']); 
    });
  }

  checkClass(event) {
    return event._viewContainerRef.element.nativeElement.className.indexOf('active-link') > -1;
  }

  lmsUrl(){
    window.open(this.configs.lmsUrl, "_blank");
  }

  recruitUrl(){
    window.open(this.configs.recruitUrl, "_blank");
  }

  adminUrl(){
    window.open(this.configs.adminUrl, "_blank");
  }

  alterSideNav() {
    this.currentScreenWidth = window.innerWidth;
    if (window.innerWidth >= 992) {
      this.sideMenuFull = true;
    } else {
      this.sideMenuFull = false;
    }
  }

  timesheetUrl() {
    window.open(this.configs.timesheetUrl, "_blank");
  }
}