import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '..';

@Injectable({
  providedIn: 'root'
})
export class AssetHistoryService {
  private assetHistoryUrl = "api/v1/asset/history";
  constructor(private httpService : HttpRequestService) { }


  getAssetHistory(id:number) : Observable<any>{
    return this.httpService.get(this.assetHistoryUrl + "/" + id );
  }
}
