import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AttributesMater, EmployeeSection, EmployeeSectionFields, EmployeeSectionService } from 'src/app/core/services/employee-section-service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { FormFields } from 'src/app/shared/models/employee-section-fields-model';
import { EmployeeSectionDetails } from 'src/app/shared/models/employee-section-model';
import { AddFieldsComponent, ConfirmationBoxComponent } from '../../shared/popups/popups';

@Component({
  selector: 'app-add-employee-section',
  templateUrl: './add-employee-section.component.html',
  styleUrls: ['./add-employee-section.component.css']
})
export class AddEmployeeSectionComponent implements OnInit {

  sectionForm: FormGroup;
  isVisible = [false];
  visible: boolean = true;
  addOnBlur: boolean = false;
  id: number;
  selectValue = [];
  formFields;
  saveFilds = [];
  editIndex: number;
  isEdit: boolean = false;
  displayedColumns = ['id', 'labelName', 'fieldDescription', 'fieldType', 'required'];

  dataSource = new MatTableDataSource<FormFields>();
  constructor(private formBuilder: FormBuilder,
    public location: Location,
    private dialog: MatDialog,
    private employeeSectionService: EmployeeSectionService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private routes: Router,
    private messageService: MessageService) {
    this.sectionForm = this.formBuilder.group({
      sectionName: [''],
      sectionDescription: [''],
      formFields: this.formBuilder.array([
      ])
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.params['new'] !== 'new') {
      this.isEdit = true;

      this.employeeSectionService.sectionFieldDetails.subscribe((employeeSectionDetails: EmployeeSectionDetails) => {
        console.log(employeeSectionDetails);

        const formFieldsDetails: FormFields[] = [];
        employeeSectionDetails.fieldDefinitions.forEach(element => {
          const formFieldsDetail: FormFields = {};
          formFieldsDetail.id = element.id;
          formFieldsDetail.labelName = element.labelName;
          formFieldsDetail.fieldDescription = element.fieldDescription;
          formFieldsDetail.fieldType = element.fieldType
          formFieldsDetail.placeholder = element.attributes.placeholder;
          formFieldsDetail.isRequired = element.attributes.isRequired;
          formFieldsDetail.values = element.attributes.values;
          formFieldsDetails.push(formFieldsDetail);
        });
        this.sectionForm.patchValue({
          "sectionName": employeeSectionDetails.sectionName,
          "sectionDescription": employeeSectionDetails.sectionDescription
        });
        this.id = employeeSectionDetails.id;
        this.dataSource.data = formFieldsDetails;
      });
    }
  }

  getFormFields() {
    let index = this.route.snapshot.params['new'];
  }

  Fields(): FormArray {
    return this.sectionForm.get("formFields") as FormArray;
  }

  newFields(index): FormGroup {
    let i = this.saveFilds.length - 1;
    return this.formBuilder.group({
      id: index,
      labelName: [this.saveFilds[i]?.labelName],
      fieldType: [this.saveFilds[i]?.fieldType],
      placeholder: [this.saveFilds[i]?.placeholder],
      fieldDescription: [this.saveFilds[i]?.fieldDescription],
      isRequired: [this.saveFilds[i]?.isRequired],
      values: [this.saveFilds[i]?.values],
    });
  }

  addFields(index) {
    this.Fields().push(this.newFields(index));
    this.isVisible.push(false)
  }

  deleteFields(i: number) {
    this.saveFilds.splice(i, 1);
    this.selectValue.splice(i, 1);
    this.Fields().removeAt(i);
  }

  addFieldsToSection() {
    // if(this.isEdit) {
    //   this.dataSource.splice(this.editIndex, 1)
    // }
    this.selectValue.filter((res, index) => {
      if ((this.sectionForm.get('formFields')?.value)[index]) {
        (this.sectionForm.get('formFields')?.value)[index].values = res;
      }
    });


    const employeeSection: EmployeeSection = {};
    const employeeSectionFieldsMaster: EmployeeSectionFields[] = [];
    employeeSection.sectionName = this.sectionForm.value.sectionName;
    employeeSection.sectionDescription = this.sectionForm.value.sectionDescription;

    this.sectionForm.value.formFields.forEach(v => {

      const employeeSectionFields: EmployeeSectionFields = {};
      const attributesValue: AttributesMater = {};

      employeeSectionFields.id = v.id;
      employeeSectionFields.labelName = v.labelName;
      employeeSectionFields.fieldDescription = v.fieldDescription;
      employeeSectionFields.fieldType = v.fieldType;

      attributesValue.placeholder = v.placeholder;
      attributesValue.isRequired = v.isRequired;
      attributesValue.values = v.values;

      employeeSectionFields.attributes = attributesValue;
      employeeSectionFieldsMaster.push(employeeSectionFields);
    });

    employeeSection.fieldDefinitions = employeeSectionFieldsMaster;
    if (this.isEdit == false) {
      this.employeeSectionService.addEmployeeSection(employeeSection).subscribe(data => {
        this.messageService.display('Section Fields added successfully! Section ID : ' + data, 'OK', 'success');
        this.loaderService.updateInboxAction(true);
        this.routes.navigate(['/employee-section']);
        console.log(data);
      })
    }

    if (this.isEdit == true) {
      employeeSectionFieldsMaster.splice(0);
      console.log(employeeSectionFieldsMaster);
      this.dataSource.data.forEach(v => {
        const employeeSectionFields: EmployeeSectionFields = {};
        const attributesValue: AttributesMater = {};

        employeeSectionFields.id = v.id;
        employeeSectionFields.labelName = v.labelName;
        employeeSectionFields.fieldDescription = v.fieldDescription;
        employeeSectionFields.fieldType = v.fieldType;

        attributesValue.placeholder = v.placeholder;
        attributesValue.isRequired = v.isRequired;
        attributesValue.values = v.values;

        employeeSectionFields.attributes = attributesValue;
        employeeSectionFieldsMaster.push(employeeSectionFields);
      });
      console.log(employeeSectionFieldsMaster);
      employeeSection.fieldDefinitions = employeeSectionFieldsMaster;

      this.employeeSectionService.updateEmployeeSectionFields(employeeSection, this.id).subscribe(data => {
        console.log(data);
        this.messageService.display('Section Fields added successfully! Section ID : ' + data, 'OK', 'success');
        this.loaderService.updateInboxAction(true);
        this.routes.navigate(['/employee-section']);
      });
    }
  }

  addFieldsDialog(index, rowId?) {
    // this.isEdit=index > 0 ? true : false;
    console.log(this.isEdit);
    this.editIndex = index;
    const dialogRef = this.dialog.open(AddFieldsComponent, {
      disableClose: false,
      width: '50%',
      data: { dataSource: this.dataSource[index], isEdit: this.isEdit }
    });
    dialogRef.afterClosed().subscribe(result => {

      let formFieldsDetails: FormFields = {};
      if (result?.fieldsForm) {
        result.fieldsForm.id = null;
        this.saveFilds.push(result.fieldsForm);
        this.selectValue.push(result.optionValue);

        if(this.isEdit==true){
          result.fieldsForm.values = result.optionValue;
        }
        if(result.fieldsForm.values == undefined){
          result.fieldsForm.values = [];
        }
        formFieldsDetails = result.fieldsForm;
        this.dataSource.data.forEach(v => {
          formFieldsDetails.id = null;
        });

        this.addFields(index);
        this.loaderService.updateInboxAction(true);
        let temp = [];
        temp = this.dataSource.data;
        temp.push(formFieldsDetails);

        this.dataSource.data = temp;
        console.log(this.dataSource);
      }
    });
  }

  // deleteFields(index) {
  //    this.saveFilds.splice(index,1);
  //    this.quantities().removeAt(index);
  // }

  deleteFieldDialog(rowId) {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: '30%',
      data: { calendarId: rowId, title: 'Confirm Deletion', message: 'Are you sure you want to remove the field' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.onYesClick(rowId)
      }
    });
  }

  onYesClick(rowId) {
    this.messageService.display('Field successfully removed from section. ', 'OK', 'success');
    this.loaderService.updateInboxAction(true);
  }

}
