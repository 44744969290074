import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { RecruiterModel } from '../../shared/models/recruiter-model';

@Injectable({
  providedIn: 'root'
})
export class RecruiterService {

  private stateUrl = 'api/v1/recruiter';

  constructor(private httpRequest: HttpRequestService) { }

  fetchRecruiters(): Observable<RecruiterModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchRecruiter(recruiterId): Observable<RecruiterModel> {
    return this.httpRequest.get(this.stateUrl + '/' + recruiterId + '?type=EXTERNAL');
  }

  updateRecruiter(recruiterModel: RecruiterModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, recruiterModel);
  }

  addRecruiter(recruiterModel: RecruiterModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, recruiterModel);
  }
}
