import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AasignAsset } from 'src/app/shared/models/aasign-asset';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class AssignAssetService {
  private assetViewUrl = 'api/v1/asset/my';

  constructor(private Httprequest:HttpRequestService,router:Router) { }
  viewAllAssignAsset():Observable<AasignAsset[]>{
    return this.Httprequest.get(this.assetViewUrl);
  }
}
