import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetManagementService } from 'src/app/core/services/asset-management.service';
import { AssetManagement } from 'src/app/shared/models/asset-management';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddFieldsComponent, ViewAssetDetailsComponent, ViewAssetHistoryComponent } from 'src/app/shared/popups/popups';
import { AssetAllocationService } from 'src/app/core/services/asset-allocation.service';
import { AssetAllocation } from 'src/app/shared/models/asset-allocation';
import { id } from 'date-fns/locale';
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { PopupService } from '@ng-bootstrap/ng-bootstrap/util/popup';
import { MessageService } from 'src/app/shared/factory/message.service';
@Component({
  selector: 'app-asset-allocation',
  templateUrl: './asset-allocation.component.html',
  styleUrls: ['./asset-allocation.component.css']
})
export class AssetAllocationComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'assetName', 'assetTag', 'employee', 'assetAction', 'view',];
  dataSource: MatTableDataSource<AssetAllocation>;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizes = [100,150,200,250,300];
  router: any;

  constructor(private assetAllocationService: AssetAllocationService, public dialog: MatDialog, router: Router,private messageService:MessageService) { }

  ngOnInit(): void {
    this.getAllApprover();
    this.dataSource = new MatTableDataSource<AssetAllocation>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  goToAssetAppoveList() {
    this.router.navigate(['/asset-approver-request']);
  }
 
  getAllApprover() {
    this.assetAllocationService.getAllApproverList().subscribe((res: AssetAllocation[]) => {
      this.dataSource = new MatTableDataSource<AssetAllocation>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  Approve(id) {
    this.assetAllocationService.Approve(id).subscribe((res: AssetAllocation[]) => {
      this.dataSource = new MatTableDataSource<AssetAllocation>(res);
      this.messageService.display('Asset Assign to employee successfully. ', 'OK', 'success');
      this.getAllApprover();
    },
      error => console.log(error));
  }
  reject(id) {    
    this.assetAllocationService.reject(id).subscribe((res: AssetAllocation[]) => {
      this.dataSource = new MatTableDataSource<AssetAllocation>(res);
      this.messageService.display('Asset Request Rejected. ', 'OK', 'success');
      this.getAllApprover();
    },
      error => console.log(error));
  }

  openOfferDialog(row) {
    const dialogRef = this.dialog.open(ViewAssetHistoryComponent, {
      disableClose: false,
      width: '50%',
      data: {dataSource: row, isEdit: false, id: row?.assetId }  
    });
  }
  
}

function res(res: any) {
  throw new Error('Function not implemented.');
}
function reject() {
  throw new Error('Function not implemented.');
}

