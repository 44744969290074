import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(email): any {
    if(email) {
     let index = email.indexOf('@');
     return email.slice(0,index);
    }
 }

}
