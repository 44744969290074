import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { JobRequisitionModel } from '../../shared/models/job-requisition-model';

@Injectable({
  providedIn: 'root'
})
export class JobRequisitionService {

  private stateUrl = 'api/v1/job-requisition';

  constructor(private httpRequest: HttpRequestService) { }

  fetchJobRequisitions(): Observable<JobRequisitionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/inprogress');
  }

  fetchJobRequisitionsInProgress(): Observable<JobRequisitionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/inprogress');
  }

  //fetchOpenJobRequisitions(): Observable<JobRequisitionModel[]> {
   // return this.httpRequest.get(this.stateUrl + '/open');
  //}

  fetchJobRequisition(jobRequisitionId): Observable<JobRequisitionModel> {
    return this.httpRequest.get(this.stateUrl + '/' + jobRequisitionId);
  }

  updateJobRequisition(jobRequisitionModel: JobRequisitionModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, jobRequisitionModel);
  }

  addJobRequisition(jobRequisitionModel: JobRequisitionModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, jobRequisitionModel);
  }

  updateStatus(data: object): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-status', data);
  }

  fetchJobRequisitionStats(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/stats');
  }

  fetchJobRequisitionsSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/filter/count', searchFormFields);
  }

  fetchOpenJobRequisitions(pageNo: number, pageSize: number): Observable<JobRequisitionModel[]> {
    return this.httpRequest.get(this.stateUrl + '/open?page=' + pageNo + '&size=' + pageSize);
  }  
  fetchOpenJobRequisitionCount(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/count/open');
  }
  fetchJobRequisitionsSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }
}
