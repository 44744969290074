import { Injectable } from '@angular/core';
import { BehaviorSubject, config } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  display(msg, action, alertType) {
    this.snackBar.open(msg, action, { panelClass: 'snackbar-' + alertType, horizontalPosition: 'center', verticalPosition: 'top' });
  }
  error(msg,action){
    this.snackBar.open(msg, action);

  }
}