import { U } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../configs/app-config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  configs = new AppConfigService();

  constructor(public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    if (sessionStorage.getItem('isLoggedIn')) {
      if (state.url === '/login') { this.router.navigate(['/']); };

      if(state.url === '/employees') {
        return isHavingPermission('ROLE_MANAGE_EMPLOYEE');
      }
      if(state.url === '/employee-section') {
        return isHavingPermission('ROLE_MANAGE_EMPLOYEE_SECTION')
      }
      if(state.url === '/job-offers'){
        return isHavingPermission('ROLE_OFFER_JOB_LETTER');
      }
      // logged in so return true
      return true;
    }


    if(this.configs.authenticationConfigurationType=='local'){
      debugger;
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }else{
      window.open(this.configs.identityServerUrl,'_self');
    }
    // not logged in so redirect to login page with the return url

   //  window.open(this.configs.identityServerUrl,'_self');
    
    return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
  canActivateMain(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  } 

}
function isHavingPermission(permissionName: string) {
  var loginData = sessionStorage.getItem('loginData');
  if(loginData) {
    loginData = JSON.parse(loginData);
    var userRoles = loginData['userRoles'];
    if(userRoles) {
     var permissions = [];
      userRoles.forEach(function(v) {
          v.permissions.forEach(m =>{
            permissions.push(m.name);
          })
      });
      return permissions.includes(permissionName);
    }
  }
}

