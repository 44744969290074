import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateService } from '../../core/services/candidate.service';
import { UserService } from '../../core/services/user.service';
import { EmployeeService } from '../../core/services/employee.service';
import { dateTimeFormatters, base64toBlob } from '../factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { BoardingModel, BoardingFormBuilder } from '../models/boarding-model';
import { CommonService } from '../../shared/factory/common.service';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { AssetManagementService } from 'src/app/core/services/asset-management.service';
import { AssetAllocation } from '../models/asset-allocation';
import { Assetallocation } from '../models/assetallocation';
import { DatePipe } from '@angular/common/';
import { supplierModelService } from 'src/app/core/services/add-supplier.service';
import { SupplierFormBuilder, supplierModel } from '../models/add-supplier';

// Candidate Offer Dialog
@Component({
    selector: 'app-candidate-offer-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>local_offer</mat-icon><span>&nbsp;&nbsp;{{data.id ? 'Job Offer' : 'Select Offer Letter'}}</span></h2>
    <hr/>
     <mat-dialog-content>
      <p>
        <label>Offer File</label>
        <a mat-raised-button color="warn" class="w-100 text-left" (click)="fileInput.click()">
          <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
          <span>{{offerFile.value ? selectedFileName : "Select (.pdf,.doc,.docx)"}}</span>
          <input #fileInput [formControl]="offerFile" type="file" (change)="onFileInput($event)" style="display:none;" accept=".pdf,.doc,.docx" [required]="data.id ? 'false' : 'true'" />
        </a>
        <mat-error *ngIf="offerFile.invalid">Offer Letter is required!</mat-error>
      </p>
      <p *ngIf="data.id">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Job Offer Status</mat-label>
          <mat-select [formControl]="offerStatus" [required]="data.id ? 'false' : 'true'">
            <mat-option value="OFFERED">Offered</mat-option>
            <mat-option value="REJECTED">Rejected</mat-option>
            <mat-option value="NO_SHOWUP">No Showup</mat-option>
          </mat-select>
          <mat-hint>Select offer status</mat-hint>
          <mat-error *ngIf="offerStatus.invalid">Required!</mat-error>
        </mat-form-field>
      </p>
     </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="offerFile.invalid || offerStatus.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">Send</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class CandidateOfferDialogComponent implements OnInit {

    offerFile = new FormControl('', [this.data.id ? Validators.nullValidator : Validators.required]);
    offerStatus = (this.data.id ? new FormControl(this.data.jobOfferStatus, [Validators.required]) : new FormControl(''));
    selectedFileName = "";
    offerLetter: any = null;

    constructor(
        public dialogRef: MatDialogRef<CandidateOfferDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService
    ) { }

    ngOnInit() { }

    onFileInput($event) {
        var regex = /(.*?)\.(docx|doc|pdf)$/;
        if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
            this.selectedFileName = $event.srcElement.files[0].name;
            this.offerLetter = $event.srcElement.files[0];
        }
    }

    applyAction() {
        this.data.jobOfferStatus = null;
        let formData: FormData = new FormData();
        if (this.offerLetter !== null) formData.append('offerLetter', this.offerLetter, this.offerLetter.name);
        if (this.data.id) {
            this.data.jobOfferStatus = this.offerStatus.value;
            let jobOfferDTOBlob = new Blob([JSON.stringify(this.data)], {
                type: 'application/json'
            });
            formData.append('jobOfferDTO', jobOfferDTOBlob);
            this._candidateService.updateOfferLetter(formData).subscribe(res => {
                this.messageService.display('Updating offer letter is successfull!', 'OK', 'success');
                this.dialogRef.close('applied');
            });
        } else {
            let jobOfferDTOBlob = new Blob([JSON.stringify(this.data)], {
                type: 'application/json'
            });
            formData.append('jobOfferDTO', jobOfferDTOBlob);
            this._candidateService.sendOfferLetter(formData).subscribe(res => {
                this.messageService.display('Sending offer letter is successfull!', 'OK', 'success');
                this.dialogRef.close('applied');
            });
        }
    }

}

// Interview Action Dialog
@Component({
    selector: 'app-interview-action-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>feedback</mat-icon><span>&nbsp;&nbsp;Select Action</span></h2>
    <hr/>
    <mat-dialog-content>
      <p class="mb-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Permissions</mat-label>
          <mat-select [formControl]="action" required>
            <mat-option value="">None</mat-option>
            <mat-option value="NEXT_ROUND">Next Round</mat-option>
            <mat-option value="ONHOLD">On Hold</mat-option>
            <mat-option value="SELECTED">Selected</mat-option>
            <mat-option value="REJECTED">Rejected</mat-option>
          </mat-select>
          <mat-hint>Select Permissions</mat-hint>
          <mat-error *ngIf="action.invalid">Required!</mat-error>
        </mat-form-field>
      </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="action.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">Apply</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class InterviewActionDialogComponent implements OnInit {

    action = new FormControl('', [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<InterviewActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService
    ) { }

    ngOnInit() { }

    applyAction() {
        let formData = { "id": this.data.interviewId, "interviewRoundId": this.data.roundId, "interviewRoundStatus": this.action.value };
        this._candidateService.updateInterviewAction(formData).subscribe(res => {
            this.messageService.display('Update is successfull!', 'OK', 'success');
            this.dialogRef.close('applied');
        });
    }

}

// Employee Startup Dialog
@Component({
    selector: 'app-employee-startup-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>person</mat-icon><span>&nbsp;&nbsp;Create Employee</span></h2>
    <hr/>
    <mat-dialog-content>
        <p>
            <label>Aggreement File</label>
            <a mat-raised-button color="warn" class="w-100 text-left" (click)="fileInput.click()">
                <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
                <span>{{inputFile.value ? selectedFileName : "Select (.pdf,.doc,.docx)"}}</span>
                <input #fileInput [formControl]="inputFile" type="file" (change)="onFileInput($event)" style="display:none;" accept=".pdf,.doc,.docx" required />
            </a>
            <mat-error *ngIf="inputFile.invalid">Agreement is required!</mat-error>
        </p>
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Manager Employee</mat-label>
                <mat-select [formControl]="managerEmpId" required>
                    <mat-option *ngFor="let employee of data.employeeLite; let m = index;" [value]="employee.employeeLite.id">{{employee.employeeLite.firstName + ' ' + employee.employeeLite.lastName}}</mat-option>
                </mat-select>
                <mat-hint>Select manager employee</mat-hint>
                <mat-error *ngIf="managerEmpId.invalid">Employee required!</mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Joining Date</mat-label>
                <input matInput readonly [formControl]="dateOfJoining" [matDatepicker]="datepicker1" placeholder="Select joining date" disabled>
                <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #datepicker1 disabled="false"></mat-datepicker>
                <mat-hint>Select joining date</mat-hint>
            </mat-form-field>
        </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="inputFile.invalid || managerEmpId.invalid || dateOfJoining.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">Send</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class EmployeeStartupDialogComponent implements OnInit {

    inputFile = new FormControl('', [Validators.required]);
    managerEmpId = new FormControl('', [Validators.required]);
    dateOfJoining = new FormControl('', [Validators.required]);
    selectedFileName = "";
    agreementLetter: any = null;

    constructor(
        public dialogRef: MatDialogRef<CandidateOfferDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService
    ) { }

    ngOnInit() { }

    onFileInput($event) {
        var regex = /(.*?)\.(docx|doc|pdf)$/;
        if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
            this.selectedFileName = $event.srcElement.files[0].name;
            this.agreementLetter = $event.srcElement.files[0];
        }
    }

    applyAction() {
        let formData: FormData = new FormData();
        if (this.agreementLetter !== null) formData.append('agreementFile', this.agreementLetter, this.agreementLetter.name);
        let employeeDTO = new Blob([JSON.stringify({ "managerEmpId": this.managerEmpId.value, "dateOfJoining": dateTimeFormatters.formatDate(this.dateOfJoining.value) })], {
            type: 'application/json'
        });
        formData.append('employeeDTO', employeeDTO);
        this._candidateService.sendConfirmation(formData, this.data.jobOfferId).subscribe(res => {
            this.messageService.display('Confirmation sending is successfull!', 'OK', 'success');
            this.dialogRef.close('applied');
        });
    }

}

// Profile Picture Dialog (Employee, etc)
@Component({
    selector: 'app-profile-picture-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>{{data.profileSelected ? 'edit' : 'add_a_photo'}}</mat-icon><span>&nbsp;&nbsp;Profile Picture</span></h2>
    <hr/>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <label>Profile Picture</label>
                    <a mat-raised-button color="warn" class="w-100 text-left" (click)="fileInput.click()">
                        <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
                        <span>{{profilePicture.value ? selectedFileName : "Select (.png,.jpg,.jpeg)"}}</span>
                        <input #fileInput [formControl]="profilePicture" type="file" (change)="fileChangeEvent($event)" style="display:none;" accept=".png,.jpg,.jpeg" required />
                    </a>
                    <mat-error *ngIf="profilePicture.invalid">Profile picture is required!</mat-error>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 5"
                        [resizeToWidth]="256"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <img [src]="croppedImage" />
                </p>
            </div>
        </div>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="profilePicture.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">{{data.profileSelected ? 'Update' : 'Upload'}} Profile Picture</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class ProfilePictureDialogComponent implements OnInit {

    profilePicture = new FormControl(null, [Validators.required]);
    imageChangedEvent: any = '';
    croppedImage: any = '';
    selectedFileName;

    constructor(
        public dialogRef: MatDialogRef<ProfilePictureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private messageService: MessageService,
        private employeeService: EmployeeService
    ) { }

    ngOnInit() { }

    fileChangeEvent(event: any): void {
        if (this.profilePicture.value) {
            this.selectedFileName = event.srcElement.files[0].name;
            this.imageChangedEvent = event;
        } else {
            this.selectedFileName = null;
            this.imageChangedEvent = '';
            this.croppedImage = '';
        }
    }

    imageCropped(image: string) {
        this.croppedImage = image;
    }

    imageLoaded() {
        // show cropper
    }

    loadImageFailed() {
        this.messageService.display('Image selection failed!', 'OK', 'error');
    }

    applyAction() {
        // Split the base64 string in data and contentType
        var block = this.croppedImage.base64.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];
        // Convert it to a blob to upload
        var blob = base64toBlob(realData, contentType);
        let formData: FormData = new FormData();
        if (this.profilePicture !== null) formData.append('profilePic', blob, this.selectedFileName);
        this.employeeService.uploadEmployeeProfilePic(this.data.employeeId, formData).subscribe((res) => {
            this.messageService.display('Profile picture is uploaded successfully!', 'OK', 'success');
            this.dialogRef.close({ data: 'uploaded', img: this.croppedImage });
        });
    }

}

// Dashboard Help Dialog
@Component({
    selector: 'app-dashboard-help-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>feedback</mat-icon><b>&nbsp;&nbsp;Help</b></h2>
    <hr/>
    <mat-dialog-content>
        <mat-accordion>
            <mat-expansion-panel class="mt-2" [expanded]="contentId == 0" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>1. Employee Count</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- The total number of members employed at the company over time.</p>
                <p>- Includes currently working and not-working both.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 1" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>2. Interview Statistics</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- The total number of pending, in-progress and on-hold candidates.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 2" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>3. Candidate Count</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- The total number of candidates available right now.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 3" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>4. Candidate Schedule</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Displays interview schedules per date with details.</p>
                <p>- Includes completed interviews.</p>
                <p>- Previous months interview schedules can also be viewed.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 4" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>5. Rejection Ratio</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Rejection Ratio is a measure of rejected candidates to interviewed candidates.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 5" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>6. Requisition</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Department supervisors who want to hire additional staff, or replace employees who resign or are terminated, generally have to complete a job requisition.</p>
                <p>- A requisition is forwarded to the human resources department for handling by the recruiter.</p>
                <p>- The recruiter then places job postings based on the requisition and discussions with the hiring manager or department supervisor.</p>
                <p>- The total number of approved, rejected and open job requisitions.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 6" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>7. Candidates Added</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Total number of candidates added per month for given months.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 7" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>8. Interview Activities</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Total number of candidate interviews taken per month for given months.</p>
            </mat-expansion-panel>
            <mat-expansion-panel class="mb-2" [expanded]="contentId == 8" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>9. Shortlisted Candidates</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Total number of shortlisted candidates for given positions.</p>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`,
    styles: ['p { margin: 0; }']
})
export class DashboardHelpDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InterviewActionDialogComponent>,
        public myElement: ElementRef,
        @Inject(MAT_DIALOG_DATA) public contentId: any
    ) { }

    ngOnInit() { }

    scrollToview() {
        setTimeout(() => {
            const el = this.myElement.nativeElement.querySelector('.mat-expanded');
            el.scrollIntoView();
        }, 100);
    }
}

@Component({
    selector: 'Boarding-component',
    template: `<div [formGroup]="boardingForm"><h1 mat-dialog-title align="center"> Boarding process </h1>
    <div mat-dialog-content>
        <div class="col-sm">
        <mat-form-field>
            <input matInput placeholder="Company Name" formControlName="companyName">
        </mat-form-field>
        </div>
        <div class="col-sm">
        <mat-form-field>
            <input matInput placeholder="Department Name" formControlName="departmentName">
        </mat-form-field>
        </div>
        <div class="col-sm">
        <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Timezone</mat-label>
                                <mat-select formControlName="timeZone">
                                    <mat-option *ngFor="let timezone of timeZones; let i = index;" [value]="timezone.value + ' - ' + timezone.text">{{timezone.value + ' - ' + timezone.text}}</mat-option>
                                </mat-select>
                                <mat-hint>Select timezone</mat-hint>
         </mat-form-field>
        </div>
       
         </div>
         <div class="col-sm">
             <div mat-dialog-actions align="center">
             <button class="mat-raised-button mat-primary" align="end"(click)="save()">Save</button>
             </div>
         </div>
    </div>`
})

export class BoardingComponent implements OnInit {

    boarding: BoardingFormBuilder = new BoardingFormBuilder();
    boardingModel: BoardingModel = this.boarding.boardingModel;
    boardingForm: FormGroup = this.boarding.boardingForm;
    timeZones;
    countries;

    module = {
        name: ''
    };

    constructor(
        private userService: UserService,
        public router: Router,
        public commonServices: CommonService,
        public messageService: MessageService,
        public dialogRef: MatDialogRef<BoardingComponent>
    ) { }

    ngOnInit() {
        const temp = sessionStorage.getItem('loginData');
        this.boardingModel.moduleName = JSON.parse(temp).moduleName;
        this.commonServices.fetchTimeZones().subscribe(res => {
            this.timeZones = res;
        });
        this.commonServices.fetchCountries().subscribe(res => {
            this.countries = res.countries;
        });
    }
    save() {
        this.userService.checkBoardingStatus(this.boardingModel).subscribe((res) => {
            this.dialogRef.close();
            this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.boardingModel.moduleName, 'OK', 'success');
        });
    }
}

@Component({
    selector: 'app-confirmation-dialog',
    template: `<div mat-dialog-content>
    {{message}}
   </div>
   <div mat-dialog-actions>
     <button mat-button (click)="onNoClick()">Cancel</button>
     <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
   </div>`
})
export class ConfirmationDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string) { }
    
        onNoClick(): void {
        this.dialogRef.close();
    }
   ngOnInit() {
    }

}


//Add Fields Components


@Component({
    selector: 'Add-Fields-component',
    template: `<div><h1 mat-dialog-title class="font-weight-bold"> {{data.isEdit ? 'Edit' : 'Add'}} Field </h1>
    <hr>
    <div mat-dialog-content style="margin-top:20px" [formGroup]="fildsForm">
      <div class="row" >
            <div class="col-md-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Name*</mat-label>
                    <input matInput placeholder="Enter Name" formControlName="labelName" autofocus>
                    <mat-error *ngIf="fildsForm.get('labelName').invalid">Field Name is required!</mat-error>
                  </mat-form-field>
              </div>
              <div class="col-md-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Enter description" formControlName="fieldDescription" autofocus>
                  </mat-form-field>
              </div>
              <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Type*</mat-label>
                        <mat-select  (selectionChange)="onChangeType()" formControlName="fieldType">
                          <mat-option *ngFor="let type of types" [value]="type">
                            {{type}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="fildsForm.get('fieldType').invalid">Field Type is required!</mat-error>
                      </mat-form-field>
              </div>
              </div>
              <div class="row">
              <div class="col-md-10" *ngIf="isVisible">
                  <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Options</mat-label>
                      <input matInput placeholder="Add Value"  #selectValueInput
                      formControlName="values" 
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="addSelectValue($event)"  autofocus>
                    </mat-form-field>
              <div>
                <mat-chip-list #chipList>
                  <mat-chip
                    *ngFor="let value of selectValue; index as i"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(value)"
                  >
                    {{value}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let value of filteredSelectValue | async" [value]="value">
                    {{ value }}
                  </mat-option>
                </mat-autocomplete>
              </div>
    
            </div>
            <div class="col-md-1" *ngIf="isVisible">
                <p>
                  <button class="btn-primary" style="margin-top:20px" mat-stroked-button (click)="addSelectValueAtClick(selectValueInput, i)"><mat-icon matSuffix>add</mat-icon></button>
                </p>
            </div>
              </div>
              <div *ngIf="isType">
              <div class="row">
              <div class="col-md-12">
  
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Placeholder</mat-label>
                    <input matInput  formControlName="placeholder"  autofocus>
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
            <section class="example-section">
            <mat-checkbox class="example-margin" formControlName="isRequired">Required</mat-checkbox>
          </section>
              </div>     
          </div>
              </div>
         </div>
         <div class="col-md-12">
             <div mat-dialog-actions class="float-right" >
             <button class="mat-raised-button mat-primary" align="end"(click)="save()" [disabled]="fildsForm.invalid">Save</button>
             <button mat-raised-button mat-dialog-close class="ml-3">Cancel</button>
             </div>
         </div>
    </div>`
})

export class AddFieldsComponent implements OnInit {
    
    @ViewChild("selectValueInput") selectValueInput: ElementRef;
    types = ['Text', 'Long Text', 'Multiselect', 'Number', 'Checkbox', 'Email', 'URL', 'File', 'Multifile', 'Date', 'Time', 'Datetime', 'Dropdown List' ];
    fildsForm: FormGroup;
    isVisible = false;
    selectValue = [];
    removable: boolean = true;
    selectable: boolean = true;
    separatorKeysCodes = [ENTER, COMMA];
    isType = false;
    
    constructor(
        public dialogRef: MatDialogRef<AddFieldsComponent>,
        public myElement: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder
    ) { 
        this.fildsForm = this.fb.group({
            labelName: ['', Validators.required],
            fieldDescription: [''],
            fieldType: ['', Validators.required],
            isRequired: [false],
            placeholder: [''],
            values: [''],
        })
    }

    ngOnInit() {
        console.log(this.data.isEdit)
        if(this.data.isEdit) {
         //   console.log( this.data.dataSource)
            this.fildsForm.patchValue({labelName: this.data.dataSource?.labelName,
                fieldDescription: this.data?.dataSource?.fieldDescription,
                fieldType: this.data?.dataSource?.fieldType,
                isRequired: this.data?.dataSource?.isRequired,
                placeholder: this.data?.dataSource?.placeholder,
                values: this.data?.dataSource?.values
            }
                 )
        }
        if(this.data?.dataSource?.fieldType) {
            this.onChangeType();
        }
        
    }
    save() {
        this.dialogRef.close({fieldsForm: this.fildsForm.value, optionValue: this.selectValue});
    }

    onChangeType() {
        this.isType = true;
        if(this.fildsForm.value.fieldType == 'Multiselect' || this.fildsForm.value.fieldType == 'Dropdown List') {
         this.isVisible = true;
        }
        else {
          this.isVisible= false
        }
      }

      selected(event ): void {
        this.selectValue.push(event.option.viewValue);
        this.selectValueInput.nativeElement.value = "";
        this.fildsForm.get('values').setValue(null);
      }

      addSelectValue(event): void {
        const input = event.input;
        const value = event.value;
        // Add our value
        if ((value || "").trim()) {
          this.selectValue.push(value.trim());
        }
        // Reset the input value
        if (input) {
          input.value = "";
        }
        this.fildsForm.get('values').setValue(null);
      }

      addSelectValueAtClick(event): void {
        const input = event.nativeElement;
        const value = this.fildsForm.get('values').value;
        // Add our value
        if ((value || "").trim()) {
          this.selectValue.push(value.trim());
        }
    
        // Reset the input value
        if (input) {
          input.value = "";
        }
      }

      remove(value: any): void {
        const index = this.selectValue.indexOf(value);
        if (index >= 0) {
          this.selectValue.splice(index, 1);
        }
      }
}

// Confirmation Box

@Component({
    selector: 'app-confirm-box',
    template: `<div  mat-dialog-title><span>{{title}}</span>
    </div>
    <div mat-dialog-content style="margin-top:20px">
      <p style="font-size:16px;">{{message+"?"}} </p>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button (click)="onNoClick()">No</button>
      <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial (click)="onYesClick()">Yes</button>
    </div>`
})
export class ConfirmationBoxComponent implements OnInit {

    title:string;
    message:string;
    constructor(
        public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.title = this.data.title;
        this.message = this.data.message;
     }

     onNoClick(): void {
        this.dialogRef.close();
      }

      onYesClick() {
            this.dialogRef.close("applied");
      }
}

@Component({
    selector: 'view-asset-details',
    template: `
    <div class="float-right"  style="margin-top: -5px;" >
    <mat-icon mat-dialog-close="true">clear</mat-icon>
    </div>
    <div class="text-center" mat-dialog-title ><span > Asset Details  </span>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>ID :</b> {{this.data.dataSource.id}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Asset Tag :</b> {{this.data.dataSource.assetTag}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Asset Type :</b> {{this.data.dataSource.type}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Serial No :</b> {{this.data.dataSource.serialNo}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Model :</b> {{this.data.dataSource.model}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Status :</b> {{this.data.dataSource.status}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Asset Name :</b> {{this.data.dataSource.assetName}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Purchase Date :</b> {{this.data?.dataSource?.purchaseDate | date}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Supplier Name :</b> {{this.data?.dataSource?.supplier?.supplierName}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Order No :</b> {{this.data?.dataSource?.orderNo}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Purchase Cost :</b> {{this.data.dataSource.purchaseCost}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Warranty Month :</b> {{this.data.dataSource.warrantyMonth}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Notes :</b> {{this.data.dataSource.notes}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Default Location :</b> {{this.data.dataSource.defaultLocation}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Last Verified Date :</b> {{this.data.dataSource.lastVerifiedDate | date}} </p>
    </div>
    </div>
    
    <app-asset-history [assetId]="assetId"></app-asset-history>
    <div class="col-sm" mat-dialog-actions class="float-right">
    <button mat-flat-button color="warn" [mat-dialog-close]="true">Cancel</button>
    </div>`
})

export class ViewAssetDetailsComponent implements OnInit {
    
    @ViewChild("selectValueInput") selectValueInput: ElementRef;
    types = ['Text', 'Long Text', 'Multiselect', 'Number', 'Checkbox', 'Email', 'URL', 'File', 'Multifile', 'Date', 'Time', 'Datetime', 'Dropdown List' ];
    fildsForm: FormGroup;
    isVisible = false;
    selectValue = [];
    removable: boolean = true;
    selectable: boolean = true;
    separatorKeysCodes = [ENTER, COMMA];
    isType = false;
    assetId;

    constructor(
        public dialogRef: MatDialogRef<ViewAssetDetailsComponent>,
        public myElement: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder
    ) {
        this.fildsForm = this.fb.group({
            fieldDescription: [''],
            fieldType: ['', Validators.required],
            isRequired: [false],
            placeholder: [''],
            values: [''],
        })
        this.assetId = this.data?.id;
    }

    ngOnInit() {
    }
}
@Component({
    selector: 'asset-req-approvers',
    template: `
   
    <div [formGroup]="assetallocat" >
        <div class="float-right"  style="margin-top: -5px;" >
        <mat-icon mat-dialog-close="true">clear</mat-icon>
        </div>
        <div class="text-center"  formControlName="action"><span>Asset {{this.data.action}}</span>
                                                                           
        </div><br/>
        <div class="row" mat-dialog-content style="font-size:40px;">
            <div class="col-md-12" style="width:900px ;" >
            <div class="row"    >
                <div class="col-sm" mat-dialog-content >
                
                <mat-form-field style="font-size:14px;height: 110px;margin-left:10px; width: 250px;">
                    <mat-label>Asset Tag </mat-label>
                    <input matInput  formControlName="assetTag" readonly >
                  </mat-form-field>
                 
                </div>
                <div class="col-sm" mat-dialog-content>
                <mat-form-field style="font-size:14px; width: 250px;">
                    <mat-label>Asset Type:</mat-label>
                    <input matInput  formControlName="type" readonly>
                  </mat-form-field>
                </div>
                </div>
            </div>
                <div class="row" style="height: 110px;margin-left:10px ;">
                <div class="col-sm" mat-dialog-content *ngIf="showDeallocationFields" >
                 <mat-form-field appearance="legacy" style="font-size:14px; width: 250px;" >
                    <mat-select formControlName="employeeId" placeholder="Select Employee" style="font-size:12px;" required >
                        <mat-option *ngFor="let employee of this.data.employees;" [value]="employee.id" >
                            {{employee.firstName + ' ' + employee.lastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                <div class="col-sm" >
                <mat-form-field appearance="legacy"  style="font-size:14px; width: 250px;">
                    <mat-select formControlName="assetApproverId" placeholder="Select Approver" style="font-size:12px;" required>
                        <mat-option *ngFor="let approver of this.data.approvers;" [value]="approver.id">
                            {{approver.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
            </div>
            <div  class="float-right" style="width: 200px;">
                    <button mat-raised-button color="primary" (click)="save()">Save</button>  &nbsp;&nbsp;
                    <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="cancel">Cancel</button>
                </div>
        </div>
    </div>`
})
export class AssetReqApproversComponent implements OnInit {
     @ViewChild("selectValueInput") selectValueInput: ElementRef;
    assetallocat: FormGroup;
    showDeallocationFields = true;

    constructor(
        private assetallocateservice: AssetManagementService,
        public dialogRef: MatDialogRef<AssetReqApproversComponent>,
        public myElement: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private messageService: MessageService
    ) {
        console.log('data', this.data);
        this.assetallocat = this.fb.group({
            assetId: [this.data.dataSource ? this.data.dataSource.id : ''],
            assetTag:[this.data.dataSource ? this.data.dataSource.assetTag:''],
            supplierName:[this.data.dataSource ? this.data.dataSource.supplier.supplierName:''],
            contactNumber:[this.data.dataSource ? this.data.dataSource.supplier.contactNumber:''],
            type:[this.data.dataSource ? this.data.dataSource.type:''],
            action: [this.data.dataSource ? this.data.action : ''],
            employeeId: [''],
            assetApproverId: [''],
        })
    }

    ngOnInit() {
        if (this.data.action == 'DEALLOCATION') {
            this.showDeallocationFields = false;
          } 
    }

    save() {
        const aassetAllocate: Assetallocation = {};
        console.log(this.assetallocat.value);
        aassetAllocate.assetId = this.assetallocat.value.assetId;
        aassetAllocate.employeeId = this.assetallocat.value.employeeId;
        aassetAllocate.assetApproverId = this.assetallocat.value.assetApproverId;
        aassetAllocate.action = this.assetallocat.value.action;
        this.assetallocateservice.assetallocateemployee(aassetAllocate).subscribe((res) => {
            console.log(res);
          this.dialogRef.close();
          this.messageService.display('Allocation Request  successfully. ', 'OK', 'success');
            location.reload();
        },
        error=>console.log(error));
    
    }
    cancel(){
        window.location.reload();
    }
     
}

@Component({
    selector: 'add',
    template: `
   
   <div [formGroup]="SupplierData" >
        <div class="float-right"  style="margin-top: -5px;" >
        <mat-icon mat-dialog-close="true">clear</mat-icon>
        </div>
        <div class="text-center" >
                     Supplier detalis                                                      
        </div>
        <div class="row" mat-dialog-content style="font-size:40px;">
            <div class="col-md-12" style="width:900px ;" >
            <div class="row"    >
                <div class="col-sm" mat-dialog-content >
                
                <mat-form-field style="font-size:14px;height: 110px;margin-left:10px; width: 250px;">
                    <mat-label>supplierName</mat-label>
                    <input matInput  formControlName="supplierName"  >
                  </mat-form-field>
                
                  
                </div>
                <div class="col-sm" mat-dialog-content>
                <mat-form-field style="font-size:14px;height: 110px;margin-left:10px; width: 250px;">
                    <mat-label>contactNumber</mat-label>
                    <input matInput  formControlName="contactNumber"  >
                  </mat-form-field>
                </div>
                </div>
            </div>
            <div  class="float-right" style="width: 200px;">
                    <button mat-raised-button color="primary" (click)="updateSupplierModel()">Update</button>  &nbsp;&nbsp;
                    <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="cancel">Cancel</button>
                </div>
        </div>
    </div>`
})
export class SupplierDataAddComponent implements OnInit {
     @ViewChild("selectValueInput") selectValueInput: ElementRef;
     SupplierData: FormGroup;
     supplierAddForm: SupplierFormBuilder = new SupplierFormBuilder();
     supplierModel: supplierModel = {
        id: null,
        supplierName: '',
        contactNumber: null
      
      };
   
    constructor(
        private addsupllierservice: supplierModelService,
        public dialogRef: MatDialogRef<SupplierDataAddComponent>,
        private router:Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private messageService: MessageService
    ) {
        console.log('data', this.data);
        this.SupplierData = this.fb.group({
            id:[this.data.dataSource ? this.data.dataSource.id:'' ],
            supplierName: [this.data.dataSource ? this.data.dataSource.supplierName:'' ],
            contactNumber: [this.data.dataSource ? this.data.dataSource.contactNumber:'' ],
           
            })
    //    this.createGroup();

    }

    ngOnInit() {
        
    }
   
  
     goToSupplierList(){
        this.router.navigate(['/Supplier']);
       }
  
     updateSupplierModel(){
        const suppliers: supplierModel = {};
        suppliers.id=this.SupplierData.value.id;
        suppliers.supplierName = this.SupplierData.value.supplierName;
        suppliers.contactNumber = this.SupplierData.value.contactNumber;
       
   
          this.addsupllierservice.updateSupplier(suppliers,suppliers.id).subscribe((res) => {
            console.log(res);
            this.dialogRef.close();
            this.messageService.display('Supplier  successfully updated!  ' , 'OK', 'success');
            this.goToSupplierList;
          location.reload();
        },
        error=>console.log(error));
    
    }
   
    cancel(){
        window.location.reload();
    }
     
}

@Component({
    selector: 'view-asset-history',
    template: `
    <div class="float-right"  style="margin-top: -5px;" >
        <mat-icon mat-dialog-close="true">clear</mat-icon>
        </div>
    <div class="text-center" mat-dialog-title ><span > Asset History View </span>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>ID :</b> {{this.data.dataSource.id}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Asset Tag :</b> {{this.data.dataSource.assetTag}} </p>
    </div>
    </div>
    <div class="row">
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Asset Name :</b> {{this.data.dataSource.assetName}} </p>
    </div>
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Asset Action:</b> {{this.data.dataSource.assetAction}} </p>
    </div>

    </div>
    <div class="row">
  
    <div class="col-sm" mat-dialog-content>
    <p style="font-size:16px;"><b>Employee Name:</b> {{this.data.dataSource.employee !=null ?this.data.dataSource.employee.firstName + ' ' +  this.data.dataSource.employee.lastName: "NA"}} </p>
    </div>
    </div>

   
   
  <app-asset-history [assetId]="assetId"></app-asset-history>
  <div class="col-sm" mat-dialog-actions class="float-right">
  <button mat-flat-button color="primary" [mat-dialog-close]="true">Cancel</button>
  </div>
  `
})

export class ViewAssetHistoryComponent implements OnInit {
    
    @ViewChild("selectValueInput") selectValueInput: ElementRef;
    types = ['Text', 'Long Text', 'Multiselect', 'Number', 'Checkbox', 'Email', 'URL', 'File', 'Multifile', 'Date', 'Time', 'Datetime', 'Dropdown List' ];
    fildsForm: FormGroup;
    isVisible = false;
    selectValue = [];
    removable: boolean = true;
    selectable: boolean = true;
    separatorKeysCodes = [ENTER, COMMA];
    isType = false;
    assetId;

    constructor(
        public dialogRef: MatDialogRef<ViewAssetHistoryComponent>,
        public myElement: ElementRef,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder
    ) {
        this.fildsForm = this.fb.group({
            fieldDescription: [''],
            fieldType: ['', Validators.required],
            isRequired: [false],
            placeholder: [''],
            values: [''],
        })
        this.assetId = this.data?.id;
    }

    ngOnInit() {
    }
}


