import { L } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { id } from 'date-fns/locale';
import { AssetHistoryService } from 'src/app/core/services/asset-history.service';
import { AssignAssetService } from 'src/app/core/services/assign-asset.service';
import { AasignAsset } from '../../models/aasign-asset';
import { AssetHistory } from '../../models/asset-history';

@Component({
  selector: 'app-asset-history',
  templateUrl: './asset-history.component.html',
  styleUrls: ['./asset-history.component.css']
})
export class AssetHistoryComponent implements OnInit {
  @Input() assetId;

  @Output() assetShared = new EventEmitter<any>();
 
  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['activityDescription','createDateTime'];
  dataSource:MatTableDataSource<AssetHistory>;
  @ViewChild(MatSort) sort:MatSort;
  @ViewChild(MatPaginator) paginator:MatPaginator;
  pagesize = [100,150,200,250,300];

  constructor(private assetHistoryServiceset: AssetHistoryService) { }

  ngOnInit(): void {
    console.log(this.assetId);
      this.viewAssetHistory(this.assetId)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }
  
viewAssetHistory(assetId){
   this.assetHistoryServiceset.getAssetHistory(assetId).subscribe((res:AssetHistory[] )=>{
    this.dataSource = new MatTableDataSource<AssetHistory>(res);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  });
}

}
