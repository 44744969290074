import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { ActivitiesService } from '../../core/services/activities.service';

@Component({
  selector: 'app-asset-activity',
  templateUrl: './asset-activity.component.html',
  styleUrls: ['./asset-activity.component.css']
})
export class AssetActivityComponent implements OnInit {


  constructor(private activityService: ActivitiesService, private employeeService: EmployeeService) { }

  assetActivities = [' '];
  assetActivity = [];
  allActivities = [];
  uniqueEmployeeId = [];
  profilePic = [];
  employeesId = [];
  isCheckRecord: boolean;
  spinner = true;
  pageNo = 0;
  pageSize = 100;
  img = "../../../assets/images/avatar.jpg";

  ngOnInit(): void {
    this.getEmployeeId(this.pageNo, this.pageSize, 'All Activity');
  }
  getEmployeeId(pageNo: number, pageSize: number, event) {
    this.activityService.getAssetActivities(['ASSET_ACTIVITY'], pageNo, pageSize).subscribe(res => {
      res?.listOfAssetActivity?.find(res=> {
      this.employeesId.push(res.employeeId)
          this.fetchEmpProfilePic(res.employeeId, event);
      });  
    });
  }

  getAssetActivity(pageNo, pageSize, event) {
    let activity;
    switch (event) {
      case "All Activity":
        activity = ['ASSET_ACTIVITY'];
        break;
      default:
    }
    console.log(event);
    this.activityService.getAssetActivities(activity, pageNo, pageSize).subscribe(res => {
      this.spinner = false;
      console.log(res['listOfAssetActivity']);

  res?.listOfAssetActivity.find(res=> {
        res.employeeProfilePic = this.profilePic[this.uniqueEmployeeId.indexOf(res.employeeId)]
     });
      this.allActivities = res?.listOfAssetActivity;
       res.listOfAssetActivity.filter(res=>{
        this.assetActivity.push(res);
        this.allActivities.push(res);
      });
    });

  }
  loadMoreActivity(event) {
    this.allActivities = []
    this.pageNo++;
    this.getAssetActivity(this.pageNo, this.pageSize, event);
  }
  fetchEmpProfilePic(employeeId, event) {
    this.employeeService.fetchEmpProfilePic(employeeId).subscribe((res: any) => {
      this.uniqueEmployeeId.push(employeeId);
      if (res.isProfileImageExist) {
        this.profilePic.push(res.imageBase64Encoded)
      }
      else {
        this.profilePic.push("../../../assets/images/avatar.jpg")
      }
      if(this.employeesId.length == this.uniqueEmployeeId.length) {
        this.isCheckRecord = true;
          this.getAssetActivity(this.pageNo, this.pageSize, event);
        }
    });
  }
}
