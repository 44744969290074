
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '../http/http-request.service';
import { EmployeeDocumentModel } from '../../shared/models/employee-documents-model'
import { EmployeeModel } from '../../shared/models/employee-model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDocumentService {

  private stateUrl = 'api/v1/employeeDocument';

  constructor(private httpRequest: HttpRequestService) { }


  addEmployeeDocument(employeeDocumentModel: FormData): Observable<EmployeeDocumentModel> {
    return this.httpRequest.filePut(this.stateUrl + '/add-document/', employeeDocumentModel);
  }
  fetchDocumentType(employeeId): Observable<EmployeeDocumentModel[]> {
    return this.httpRequest.get(this.stateUrl + '/' + employeeId);
  }
  fetchEmployeeDocuments(employeeId): Observable<EmployeeDocumentModel> {
    return this.httpRequest.get('api/v1/employeeDocument' + '/' + employeeId);
  }
  fetchEmployeeDocument(): Observable<EmployeeDocumentModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }
  downloadEmployeeDocument(id, employeeId): Observable<EmployeeDocumentModel> {
    return this.httpRequest.get(this.stateUrl + '/download/' + id + employeeId);
  }
  deleteDocumentByEmp(Id:number): Observable<number> {
    return this.httpRequest.delete(this.stateUrl + '/delete-document/'+Id);
  }
}
