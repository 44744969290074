import { Component, OnInit } from '@angular/core';
import { LoginModel } from '../../shared/models/login-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../core/services/login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide = true;
  loginModel: LoginModel = new LoginModel();
  returnUrl;

  constructor(
    private loginService: LoginService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (sessionStorage.getItem('isLoggedIn')) {
      this.router.navigate([this.returnUrl ? this.returnUrl : '/']);
    }
  }

  ngOnInit() {
  }

  tryLogin() {
    this.loginService.tryLogin(this.loginModel).subscribe(res => {
      sessionStorage.setItem('isLoggedIn', 'true');
      sessionStorage.setItem('loginData', JSON.stringify(res.body));
      sessionStorage.setItem('x-auth-token', res.headers.get('x-auth-token'));
      this.router.navigate([this.returnUrl ? this.returnUrl : '/']);
   });
  }

}
