import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class PeopleActivityService {
  private peopleActivityURL = "api/v1/activity/filter-specific-activity";

  constructor(private httpService : HttpRequestService) { }
  

  getpeopleActivities(activityFilters,pageNo: number, pageSize: number){
    return this.httpService.get(this.peopleActivityURL + '?page=' + pageNo + '&size=' + pageSize + '&activityFilters=' + activityFilters);
  }

}
