import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmployeeSectionFieldsDetailsModel } from "src/app/shared/models/employee-fields-edit-component";
import { EmployeeModel } from "src/app/shared/models/employee-model";
import { UserFormBuilder } from "src/app/shared/models/user-model";
import { NumberLiteralType, NumericLiteral } from "typescript";
import { HttpRequestService } from "..";

@Injectable({
    providedIn: 'root'
})

export class EmployeeSectionFieldsDetailsService {

    private stateUrl = 'api/v1/employee-section-details';

    constructor(private httpRequest: HttpRequestService) { }

    getAllSectionDetails(employeeId:number,sectionId:number): Observable<EmployeeSectionFieldsDetailsModel>{
        return this.httpRequest.get(`${this.stateUrl}/${employeeId}/${sectionId}`);
    }

    addEmployeeSection(employeeSectionFieldsDetailsModel: EmployeeSectionFieldsDetailsModel): Observable<any> {
        return this.httpRequest.post(this.stateUrl, employeeSectionFieldsDetailsModel);
    }

    fetchEmployees(pageNo, pageSize): Observable<EmployeeModel[]> {
        return this.httpRequest.get('api/v1/people' + '?page=' + pageNo + '&size=' + pageSize);
    }
  
    
    fetchActiveEmployees() :Observable<any> {
        return this.httpRequest.get('api/v1/employee/fetch/activeEmployee');
      }

    fetchApprovers(): Observable<any[]> {
        return this.httpRequest.get('api/v1/user/asset-req-approvers');
    }

    fetchEmployeesCount(): Observable<number> {
        return this.httpRequest.get('api/v1/people' + '/count');
    }

    addFilePath(formData:FormData):Observable<any>{
        return this.httpRequest.filePost(this.stateUrl+'/upload',formData);
    }
    
    fetchAllActiveEmployees(pageNo, pageSize): Observable<EmployeeModel[]> {
        return this.httpRequest.get('api/v1/people/active' + '?page=' + pageNo + '&size=' + pageSize);
    }
}

export interface FileFieldsId{
    empId?:number;
    sectionId?:number;
    fieldId?:number;
} 
export interface FileDownload{
    fileName?:string;
    empId?:number;
    sectionId?:number;
    fieldId?:number;
}