import { MessageService } from './../../shared/factory/message.service';
import { HelpService } from './../../core/services/help.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationBoxComponent } from 'src/app/shared/popups/popups';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { EmployeeSection, EmployeeSectionService } from 'src/app/core/services/employee-section-service';
import { EmployeeSectionDetails } from 'src/app/shared/models/employee-section-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-section',
  templateUrl: './employee-section.component.html',
  styleUrls: ['./employee-section.component.css']
})
export class EmployeeSectionComponent implements OnInit {
  

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'sectionName', 'createdBy', 'createdDate', 'action'];

  dataSource: MatTableDataSource<EmployeeSectionDetails>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;

  constructor(private helpService: HelpService,
    private dialog: MatDialog,
    private employeeSectionService:EmployeeSectionService,
    private messageService: MessageService,
    private router: Router,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.getAllEmployeeSectionAndFields();
    this.dataSource = new MatTableDataSource<EmployeeSectionDetails>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  routeToSectionFieldsId(employeeSectionDetails:EmployeeSectionDetails,id:number) {
    this.router.navigate(['/employee-section',id]);
    this.employeeSectionService.routeToSectionFieldsIds(employeeSectionDetails);
  }


  getAllEmployeeSectionAndFields() {
    this.employeeSectionService.getAllEmployeeSectionAndFields().subscribe((res: EmployeeSectionDetails[]) => {
      this.dataSource = new MatTableDataSource<EmployeeSectionDetails>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  findHelpUrlPage(){
    const currentPage = "/#_5_4_job_offers";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

  deleteSectionDialog(rowId) {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: '30%',
      data: { calendarId : rowId, title: 'Confirm Deletion', message: 'Are you sure you want to remove the section'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.onYesClick(rowId)
      }
    });
  }

  onYesClick(rowId) {
   this.messageService.display('Section Removed Successfully. ', 'OK', 'success');
      this.loaderService.updateInboxAction(true);
  }

}
