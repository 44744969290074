import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Pipe({
  name: 'monthToYear'
})
export class MonthToYearPipe implements PipeTransform {
  transform(value:any): any {
    var month:number;
    var year:number;
     if(Number(value)){
      month=(value%12);
      year=(value/12); 
      return +parseInt(year+'-')+ ' Y '+  +month + ' M ';
      }else{
        return value;
      }
  }                        
}
