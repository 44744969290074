import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  private assetActivityURL = "api/v1/activity/filter-specific-activity";

  constructor(private httpService : HttpRequestService) { }

  getAssetActivities(activityFilters,pageNo: number, pageSize: number) {
    return this.httpService.get(this.assetActivityURL + '?page=' + pageNo + '&size=' + pageSize + '&activityFilters=' + activityFilters);
  }
}
