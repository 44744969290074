import { Component, OnInit } from '@angular/core';
import { EmployeeFormBuilder, EmployeeModel } from '../../shared/models/employee-model';
import { UserService } from '../../core/services/user.service';
import { JobPositionService } from '../../core/services/job-position.service';
import { compareFn, CommonService } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  employee: EmployeeFormBuilder = new EmployeeFormBuilder();
  employeeModel: EmployeeModel = this.employee.employeeModel;
  employeeForm = this.employee.employeeForm;
  isProfileFetched: boolean = false;
  countries = [];
  jobTitles = fetchCachedData('jobTitles');
  departments = fetchCachedData('departments');
  recruiters = fetchCachedData('recruiters');
  jobPositions = [];
  managerEmps = [];
  profileSelected: boolean = false;
  img = './assets/images/avatar.jpg';

  constructor(
    private _commonServices: CommonService,
    private userService: UserService,
    private jobPositionService: JobPositionService,
    private employeeService: EmployeeService,
    private helpService: HelpService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router

  ) { }

  ngOnInit() {
    this.employeeForm.disable();
    this._commonServices.fetchCountries().subscribe(res => {
      this.countries = res.countries;
    });
    this.fetchMyProfile();
    this.fetchJobPositions();
    this.fetchManagerEmps();
    console.log(this.employeeModel);
    console.log(this.employee);
    this.fetchEmpProfilePic(this.employeeModel.id);
  }

  fetchEmpProfilePic(employeeId) {
    this.employeeService.fetchEmpProfilePicByUserId(JSON.parse(sessionStorage.getItem('loginData')).id).subscribe((res: any) => {
      if (res.isProfileImageExist) {
        this.profileSelected = true;
        this.img = res.imageBase64Encoded;
      }
    });
  }

  fetchMyProfile() {
    this.employeeService.fetchMyProfile().subscribe((res: EmployeeModel) => {
      this.isProfileFetched = true;
      this.employee.setModelVals(res);
    });
  }

  fetchManagerEmps() {
    this.userService.fetchEmployeesLite().subscribe(res => {
      this.managerEmps = res;
    });
  }

  fetchJobPositions() {
    this.jobPositionService.fetchJobPositions().subscribe(res => {
      this.jobPositions = res;
    });
  }

  findHelpUrlPage(){
    const currentPage = "/#_3_2_my_profile";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");   
  }
  
}
