import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-control-message',
 // templateUrl: './form-control-message.component.html',
  styleUrls: ['./form-control-message.component.css']
})
export class FormControlMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
