import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetManagement } from '../../shared/models/asset-management';
import { AssetManagementService } from 'src/app/core/services/asset-management.service';
import { MessageService } from 'src/app/shared/factory/message.service';

@Component({
  selector: 'app-add-asset-manage',
  templateUrl: './add-asset-manage.component.html',
  styleUrls: ['./add-asset-manage.component.css']
})
export class AddAssetManageComponent implements OnInit {
  
  SupplierdataAll = [];
  statusList = {};
  assetTypes = {};
  Form: FormGroup;
  
  constructor
  (private fb: FormBuilder,
   private location: Location,
   private router: Router,
   private assetService: AssetManagementService,
   private messageService: MessageService)
  { this.createGroup(); }
    
  get rf(){ return this.Form.controls; }

  ngOnInit(): void { 
    this.getSupplier();
    // this.statusList['PENDING'] = "Pending";
    this.statusList['READY_TO_DEPLOY'] = "Ready to Deploy";
    //this.statusList['ASSIGN_TO_EMPLOYEE'] = "Assign To Employee";
    //this.statusList['DEASSIGN_TO_EMPLOYEE'] = "Deassign To Employee";
    // this.statusList['ARCHIVED'] = 'Archived';
    // this.statusList['BROKEN_OR_NOT_FIXABLE'] = 'Broken - Not Fixable';
    // this.statusList['LOST_OR_STOLEN'] = 'Lost/Stolen';
    // this.statusList['OUT_FOR_DIAGNOSTICS'] = 'Out for Diagnostics';
    // this.statusList['OUT_FOR_REPAIR'] = 'Out for Repair';

    this.assetTypes['MONITOR'] = "Monitor";
    this.assetTypes['LAPTOP'] = "Laptop"
    this.assetTypes['MOUSE'] = 'Mouse';
    this.assetTypes['KEYBOARD'] = 'Keyboard';
    this.assetTypes['CPU'] = 'Cpu';
    this.assetTypes['LAN_CABLE'] = 'Lan Cable';
    this.assetTypes['HEADSET'] = 'Headset';
    this.assetTypes['NETWORK_SWITCH'] = 'Network Switch';
    this.assetTypes['FIREWALL'] = 'Firewall';
    this.assetTypes['ACCESS_POINT'] = 'Access Point';
    this.assetTypes['ROUTER'] = 'Router';
    this.assetTypes['WORK_STATION'] = 'Work Station';
    this.assetTypes['CCTV'] = 'CCTV';
    this.assetTypes['PRINTER_SCANNER'] = 'Printer and Scanner';
    this.assetTypes['CHAIR']='Chair';
    this.assetTypes['DESK'] = 'Desk';
    this.assetTypes['TELEVISION'] = 'Television';

   }
 
    getSupplier() {
    this.assetService.getSupplierList().subscribe((data) => {
      this.SupplierdataAll = data;
    },
      error => console.log(error));
  }
  
  createGroup() {
    this.Form = this.fb.group({
      assetTag: ['', Validators.required ],
      type: ['', Validators.required ],
      serialNo: ['', Validators.required ],
      model:['', Validators.required],
      status:['', Validators.required],
      assetName:['', Validators.required],
      purchaseDate:[''],
      supplier: ['', Validators.required],
      orderNo:[''],
      purchaseCost:[''],
      warrantyMonth:[''],
      notes:[''],
      defaultLocation:[''],
      lastVerifiedDate : ['']
    });
 }

 goToAssetList(){
  this.router.navigate(['/asset']);
 }

 onSave() {
 let asset: AssetManagement = {};
  asset = this.Form.value; 
  this.assetService.createAsset(asset).subscribe( data =>{
    this.messageService.display('Asset added successfully. ', 'OK', 'success');
    this.goToAssetList();
  },
    error => console.log(error));
 }

}
