import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CandidateService } from '../../core/services/candidate.service';
import { UserService } from '../../core/services/user.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CandidateOfferDialogComponent, EmployeeStartupDialogComponent } from '../../shared/popups/popups';
import { AppConfigService } from '../../configs/app-config.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { fetchDisplayData, fetchMultiDisplayData, customValidators } from '../../shared/factory/common.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MessageService } from '../../shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../core/services/employee.service';
import { HelpService } from '../../core/services/help.service';


@Component({
  selector: 'app-job-offer',
  templateUrl: './job-offer.component.html',
  styleUrls: ['./job-offer.component.css']
})
export class JobOfferComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id', 'name', 'jobPosition', 'experience', 'email', 'mobile', 'jobRequisitionName', 'status', 'createdDate','action'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;
  totalCount = 0;
  auth_token = sessionStorage.getItem('x-auth-token');
  jobTitles = fetchCachedData('jobTitles');
  jobPositions = fetchCachedData('jobPositions');
  fetchDisplayData = fetchDisplayData;
  fetchMultiDisplayData = fetchMultiDisplayData;
  searchFormData = {
    name: '',
    positionId: '',
    titleId: '',
    email: '',
    phoneNumber: '',
    status: ''
  };
  searchFormFields: FormGroup = new FormGroup({
    name: new FormControl(this.searchFormData.name),
    positionId: new FormControl(this.searchFormData.positionId),
    titleId: new FormControl(this.searchFormData.titleId),
    email: new FormControl(this.searchFormData.email, [customValidators.emailValidator()]),
    phoneNumber: new FormControl(this.searchFormData.phoneNumber, [customValidators.numberValidator()]),
    status: new FormControl(this.searchFormData.status)
  });
  searched = false;
  expanded = true;

  constructor(
    private candidateService: CandidateService,
    public dialog: MatDialog,
    public _configUrls: AppConfigService,
    private userService: UserService,
    private eleRef: ElementRef,
    private helpService: HelpService,
    private messageService: MessageService,
    private employeeService: EmployeeService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.fetchJobOffersCount();
    this.fetchJobOffers(this.pageNo, this.pageSize);
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(options => {
      this.pageNo = options.pageIndex;
      this.pageSize = options.pageSize;
      this.searched ? this.searchJobOffers() : this.fetchJobOffers(this.pageNo, this.pageSize);
    });
  }

  fetchJobOffersCount() {
    this.candidateService.fetchJobOffersCount().subscribe((res: any) => {
      this.totalCount = res;
    });
  }

  fetchJobOffers(pageNo, pageSize) {
    this.candidateService.fetchJobOffers(pageNo, pageSize).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openOfferDialog(data) {
    const dialogRef1 = this.dialog.open(CandidateOfferDialogComponent, {
      data: data
    });

    dialogRef1.afterClosed().subscribe(result => {
      if (result == 'applied') {
        this.pageNo = 0;
        this.pageSize = 10;
        this.searched ? this.searchJobOffers() : this.fetchJobOffers(this.pageNo, this.pageSize);
      }
    });
  }

  employeeStartup(jobOfferId) {
    this.userService.fetchEmployeesLite().subscribe(res => {
      const dialogRef2 = this.dialog.open(EmployeeStartupDialogComponent, {
        data: { employeeLite: res, jobOfferId: jobOfferId }
      });

      dialogRef2.afterClosed().subscribe(result => {
        if (result == 'applied') {
          this.pageNo = 0;
          this.pageSize = 10;
          this.searched ? this.searchJobOffers() : this.fetchJobOffers(this.pageNo, this.pageSize);
        }
      });
    });
  }

  openSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.add('scrollHide');
    this.sidenav.open();
  }

  closeSidenav() {
    this.eleRef.nativeElement.offsetParent.classList.remove('scrollHide');
    this.sidenav.close();
  }

  searchJobOffers() {
    this.searchFormData = this.searchFormFields.value;
    let obj = {};
    this.searchFormData.name ? (obj['name'] = this.searchFormData.name) : null;
    this.searchFormData.phoneNumber ? (obj['phoneNumber'] = this.searchFormData.phoneNumber) : null;
    this.searchFormData.positionId ? (obj['positionId'] = this.searchFormData.positionId) : null;
    this.searchFormData.email ? (obj['email'] = this.searchFormData.email) : null;
    this.searchFormData.status ? (obj['status'] = this.searchFormData.status) : null;
    this.searchFormData.titleId ? (obj['titleId'] = this.searchFormData.titleId) : null;
    obj != {} ? this.candidateService.fetchJobOffersSearchCountFilter(obj).subscribe(res => {
      this.totalCount = res;
      (res > 0) ? this.candidateService.fetchJobOffersSearchFilter(obj, this.pageNo, this.pageSize).subscribe(resp => {
        this.dataSource = new MatTableDataSource<any>(resp);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.searched = true;
        this.closeSidenav();
      }) : this.messageService.display('No records found!', 'OK', 'warn');
    }) : null;
  }

  refreshSearch() {
    let canBeSearched = false;
    this.searchFormData.email ? (canBeSearched = true) : null;
    this.searchFormData.name ? (canBeSearched = true) : null;
    this.searchFormData.phoneNumber ? (canBeSearched = true) : null;
    this.searchFormData.positionId ? (canBeSearched = true) : null;
    this.searchFormData.status ? (canBeSearched = true) : null;
    this.searchFormData.titleId ? (canBeSearched = true) : null;
    if (canBeSearched) {
      this.searchJobOffers();
    } else {
      this.searched = false;
      this.pageNo = 0;
      this.fetchJobOffersCount();
      this.fetchJobOffers(this.pageNo, this.pageSize);
    }
  }

  resetForm() {
    this.searchFormFields.reset({
      name: '',
      positionId: '',
      titleId: '',
      email: '',
      phoneNumber: '',
      status: '',
    });
    this.searchFormData = this.searchFormFields.value;
    if (this.searched) {
      this.searched = false;
      this.pageNo = 0;
      this.fetchJobOffersCount();
      this.fetchJobOffers(this.pageNo, this.pageSize);
    }
  }
  findHelpUrlPage(){
    const currentPage = "/#_5_4_job_offers";
    window.open(this.helpService.HELP_BASE_URL + currentPage, "_blank");
  }

}
