import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { supplierModelService } from 'src/app/core/services/add-supplier.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { supplierModel } from 'src/app/shared/models/add-supplier';
import { SupplierDataAddComponent } from 'src/app/shared/popups/popups';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css']
})
export class SupplierComponent implements OnInit {
 
  SupplierdataAll = [];
  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id','supplierName','contactNumber','action'];
  dataSource:MatTableDataSource<supplierModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSizes = [100,200,300,400,500];
 


  
  
  constructor(private supplierModelService:supplierModelService,
    private messageService: MessageService,
    private router:Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getSupplier();
    this.dataSource = new MatTableDataSource<supplierModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }
 

  getSupplier() {
    
    this.supplierModelService.getSupplierLists().subscribe((data:supplierModel[]) => {
      this.dataSource = new MatTableDataSource<supplierModel>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    },
      error => console.log(error));
  }

  goToSupplierList(){
    this.router.navigate(['/supplier']);
   }
  supplierAdd(){
     const dialogRef = this.dialog.open(SupplierDataAddComponent,{
    disableClose:false,
  width:'50%',
     });
  }
  supplierAddd(row, id, action) {
    console.log(action);
    const dialogRef = this.dialog.open(SupplierDataAddComponent,{
      disableClose:false,
    width:'50%',
      data: { dataSource: row}
      
    });
    
  }
  deleteSupplierData(id) {
      this.supplierModelService.deleteSupplier(id).subscribe((res) => {
        this.messageService.display(' successfully deleted !', 'OK', 'success');
        this.goToSupplierList();
        window.location.reload();

    });    
     
    
  }

 
}
