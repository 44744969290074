import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { CandidateModel } from '../../shared/models/candidate-model';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  private stateUrl = 'api/v1/candidate';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchCandidates(pageNo: number, pageSize: number): Observable<CandidateModel[]> {
    return this.httpRequest.get(this.stateUrl + '?page=' + pageNo + '&size=' + pageSize);
  }

  fetchCandidatesCount(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/count');
  }

  fetchCandidate(candidateId): Observable<CandidateModel> {
    return this.httpRequest.get(this.stateUrl + '/' + candidateId + '?withDetail=true');
  }

  updateCandidate(formData: FormData): Observable<any> {
    return this.httpRequest.filePut(this.stateUrl, formData);
  }

  addCandidate(formData: FormData): Observable<any> {
    return this.httpRequest.filePost(this.stateUrl, formData);
  }

  referFriend(formData: FormData): Observable<any> {
    return this.httpRequest.filePost(this.stateUrl + '/refer', formData);
  }

  fetchCandidateStats(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/stats');
  }

  fetchCandidateSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/count/filter', searchFormFields);
  }

  fetchCandidateSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }

  // below requests are needed to be moved to interview services

  fetchCandidatesForInterview(pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.get('api/v1/interview/inprogress?page=' + pageNo + '&size=' + pageSize);
  }

  fetchCandidatesForInterviewCount(): Observable<any> {
    return this.httpRequest.get('api/v1/interview/inprogress/count');
  }

  fetchAllInterivews(currentMonthStartDate, currentMonthLastDate): Observable<any> {
    return this.httpRequest.post('api/v1/interview/round/date/filter', { "startTime": "00:00", "startDate": currentMonthStartDate, "endTime": "23:59", "endDate": currentMonthLastDate });
  }

  scheduleRound(roundId, formData): Observable<any> {
    return this.httpRequest.put('api/v1/interview/schedule-round/' + roundId, formData);
  }

  fetchSelectedInterview(roundId): Observable<any> {
    return this.httpRequest.get('api/v1/interview/round/' + roundId);
  }

  fetchInterviewDetails(interviewId): Observable<any> {
    return this.httpRequest.get('api/v1/interview/details/' + interviewId);
  }

  fetchInterviewDetailsTypeList(type, candidateId, requisitionId): Observable<any> {
    return this.httpRequest.get('api/v1/interview/details/' + type + '/' + candidateId + '?requisitionId=' + requisitionId);
  }

  fetchInterviewFeedbacks(): Observable<any> {
    return this.httpRequest.get('api/v1/interview/round/pending-feedback/');
  }

  fetchFeedbackInfo(roundId): Observable<any> {
    return this.httpRequest.get('api/v1/interview/feedback-support-info/' + roundId);
  }

  addFeedback(feedbackFormData): Observable<any> {
    return this.httpRequest.put('api/v1/interview/feedback', feedbackFormData);
  }

  fetchRoundDetails(interviewRoundId): Observable<any> {
    return this.httpRequest.get('api/v1/interview/round/details/' + interviewRoundId);
  }

  
  





  updateInterviewAction(formData): Observable<any> {
    return this.httpRequest.put('api/v1/interview/action', formData);
  }

  fetchInterviewStats(): Observable<any> {
    return this.httpRequest.get('api/v1/interview/stats');
  }

  fetchActivityStats(): Observable<any> {
    return this.httpRequest.get('api/v1/interview/activity/stats');
  }

  fetchShortlistedStats(): Observable<any> {
    return this.httpRequest.get('api/v1/interview/shortlisted/stats/');
  }

  fetchInterviewActionSearchFilterCount(searchFormFields): Observable<any> {
    return this.httpRequest.post('api/v1/interview/round/waiting-action/count/filter', searchFormFields);
  }
  fetchInterviewActionSearchFilter(searchFormFields,pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post('api/v1/interview/round/waiting-action/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }

  fetchCandidatesForInterviewSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post('api/v1/interview/round/count/filter', searchFormFields);
  }

  fetchCandidatesForInterviewSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post('api/v1/interview/round/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }

  // below requests are needed to be moved to job-requisition services

  fetchShortListedCandidatesCount(): Observable<any> {
    return this.httpRequest.get('api/v1/job-requisition/count/shortlist');
  }

  fetchShortListedCandidates(pageNo, pageSize): Observable<any> {
    return this.httpRequest.get('api/v1/job-requisition/shortlist?page=' + pageNo + '&size=' + pageSize);
  }

  fetchRejectedCandidatesCount(): Observable<any> {
    return this.httpRequest.get('api/v1/job-requisition/count/rejectedlist');
  }

  fetchRejectedCandidates(pageNo, pageSize): Observable<any> {
    return this.httpRequest.get('api/v1/job-requisition/rejectedlist?page=' + pageNo + '&size=' + pageSize);
  }

  fetchJobOffersCount(): Observable<any> {
    return this.httpRequest.get('api/v1/job-requisition/count/offered');
  }

  fetchJobOffers(pageNo, pageSize): Observable<any> {
    return this.httpRequest.get('api/v1/job-requisition/offered?page=' + pageNo + '&size=' + pageSize);
  }

  sendOfferLetter(formData: FormData): Observable<any> {
    return this.httpRequest.filePost('api/v1/job-requisition/offer', formData);
  }

  updateOfferLetter(formData: FormData): Observable<any> {
    return this.httpRequest.filePut('api/v1/job-requisition/offer', formData);
  }

  fetchJobOffersSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post('api/v1/job-requisition/offered/filter/count', searchFormFields);
  }

  fetchJobOffersSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post('api/v1/job-requisition/offered/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }

  fetchShortlistSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post('api/v1/job-requisition/shortlist/filter/count', searchFormFields);
  }

  fetchShortlistSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post('api/v1/job-requisition/shortlist/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }

  fetchRejectedSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post('api/v1/job-requisition/rejectlist/filter/count', searchFormFields);
  }

  fetchRejectedSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post('api/v1/job-requisition/rejectlist/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }
  // below requests are needed to be moved to employee services

  sendConfirmation(formData: FormData, jobOfferId: number): Observable<any> {
    return this.httpRequest.filePost('api/v1/employee/' + jobOfferId, formData);
  }
//  fetchOpenJobRequisitions(pageNo: number, pageSize: number): Observable<JobRequisitionModel[]> {
 //   return this.httpRequest.get(this.stateUrl + '/open?page=' + pageNo + '&size=' + pageSize);
  //}  
  fetchAwaitingActionInterviewsCount(): Observable<any> {
    return this.httpRequest.get('api/v1/interview/round/count/waiting-action');
  }

  fetchAwaitingActionInterviews(pageNo: number, pageSize: number): Observable<CandidateModel[]> {
    return this.httpRequest.get('api/v1/interview/round/waiting-action?page=' + pageNo + '&size=' + pageSize);
  }
 // fetchAwaitingActionInterviews(): Observable<CandidateModel[]> {
  //  return this.httpRequest.get('api/v1/interview/round/waiting-action');
  //}

}
