import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { DepartmentModel } from '../../shared/models/department-model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private stateUrl = 'api/v1/department';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchDepartments(): Observable<DepartmentModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchDepartment(departmentId): Observable<DepartmentModel> {
    return this.httpRequest.get(this.stateUrl + '/' + departmentId);
  }

  updateDepartment(departmentModel: DepartmentModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, departmentModel);
  }

  addDepartment(departmentModel: DepartmentModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, departmentModel);
  }
}
