import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class SSOGatewayService {

  constructor(private httpRequest: HttpRequestService) { }



  getUserInfo() {
    return this.httpRequest.get('api/v1/user/get-loggedin-user-info?moduleName=LMS');
  }

  ssoLogin() {
    return this.httpRequest.get('sso/login'+location.search);
  }

  ssoLogout() {
    return this.httpRequest.post('api/v1/sso/logout','');
  }
}
