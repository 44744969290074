import { Injectable } from '@angular/core';
import { LoginModel } from '../../shared/models/login-model';
import { HttpRequestService } from '../http/http-request.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class LoginService {

  constructor(private httpRequest: HttpRequestService) { }

  tryLogin(loginData: LoginModel) {
    const body = new HttpParams()
      .set('username', loginData.email.value)
      .set('password', loginData.password.value);
    return this.httpRequest.post('login', body, true);
  }

  logout() {
    return this.httpRequest.get('logout');
  }
}
