
import { NgModule } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
import { HomeComponent} from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { EmployeeEditComponent } from './components/employee-edit/employee-edit.component';
import { EmployeeDocumentComponent } from './components/employee-document/employee-document.component';
import { JobOfferComponent } from './components/job-offer/job-offer.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SSOGatewayComponent } from './components/sso-gateway/sso-gateway.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { EmployeeSectionComponent } from './components/employee-section/employee-section.component';
import { AddEmployeeSectionComponent } from './components/add-employee-section/add-employee-section.component';
import { AssetManagementComponent } from './components/asset-management/asset-management.component';
import { RouterModule, Routes } from '@angular/router';
import { AddAssetManageComponent } from './components/add-asset-manage/add-asset-manage.component';
import { AssetAllocationComponent } from './components/asset-allocation/asset-allocation.component';
import { AasignAssetComponent } from './components/aasign-asset/aasign-asset.component';
import { AssetActivityComponent } from './components/asset-activity/asset-activity.component';
import { PeopleActivityComponent } from './components/people-activity/people-activity.component';
import { supplierModelComponent } from './components/add-supplier/add-supplier.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { SupplierActivityComponent } from './components/supplier-activity/supplier-activity.component';

const routes: Routes = [
  { path: '', redirectTo: '/my-profile', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sso-gateway', component: SSOGatewayComponent },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: 'employees', component: EmployeeComponent },
      { path: 'employees/:employeeId', component: EmployeeEditComponent },
      { path: 'employee-document/:employeeId', component: EmployeeDocumentComponent },
      { path: 'job-offers', component: JobOfferComponent },
      { path: 'my-profile', component: ProfileComponent },
      { path: 'employee-section', component: EmployeeSectionComponent},
      { path: 'employee-section/:new', component: AddEmployeeSectionComponent},
      { path: 'employee-section/:id', component: AddEmployeeSectionComponent},
      { path: 'asset', component:AssetManagementComponent},
      { path: 'asset/:new', component:AddAssetManageComponent},
      { path: 'asset-approver-request', component:AssetAllocationComponent},
      { path: 'my-asset',component:AasignAssetComponent},
      {path: 'asset-activity', component:AssetActivityComponent},
      {path: 'people-activity', component:PeopleActivityComponent},
      {path: 'supplier/:new',component:supplierModelComponent},
      {path:'supplier',component:SupplierComponent},
      {path:'supplier-activity',component:SupplierActivityComponent}

      

     ]
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] } // need to work on design
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
    // { enableTracing: true } // <-- debugging purposes only
  )],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
