import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'; 
import { NgxPermissionsService, NgxPermissionsModule} from 'ngx-permissions';
import { ComponentsModule } from './components/components.module';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MaterialModule } from '../app/shared/modules/material/material.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { httpInterceptorProviders } from './core/interceptors';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({

  declarations: [
    AppComponent,
    PageNotFoundComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    NgxPermissionsModule,
    ComponentsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [httpInterceptorProviders ],
  bootstrap: [AppComponent]
})

export class AppModule { }
