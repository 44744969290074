import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { PeopleActivityService } from 'src/app/core/services/people-activity.service';
import { SupplierActivityService } from 'src/app/core/services/supplier-activity.service';


@Component({
  selector: 'app-supplier-activity',
  templateUrl: './supplier-activity.component.html',
  styleUrls: ['./supplier-activity.component.css']
})
export class SupplierActivityComponent implements OnInit {
allActivities: [];
spinner = true;
constructor(private supplierActivityService: SupplierActivityService,private employeeService: EmployeeService) { }
supplierActivities = [''];
supplierActivity = [];
uniqueEmployeeId = [];
profilePic = [];
employeesId = [];
pageNo = 0;
pageSize = 100;

img = "../../../assets/images/avatar.jpg";

ngOnInit(): void {
  this.getEmployeeId(this.pageNo, this.pageSize, 'All Activity');
}
getEmployeeId(pageNo: number, pageSize: number, event) {
  this.supplierActivityService.getSupplierActivities('SUPPLIER_ACTIVITY', pageNo, pageSize).subscribe(res => {
    res?.listOfSupplierActivity?.find(res => {
      this.employeesId.push(res.employeeId)
      this.fetchEmpProfilePic(res.employeeId, event);
    });
  });
}
  getSupplierActivity(pageNo, pageSize, event) {
    let activity;
    switch (event) {
      case "All Activity":
        activity = ['SUPPLIER_ACTIVITY'];
        break;
      default:
    }
    this.supplierActivityService.getSupplierActivities(activity, pageNo, pageSize).subscribe(res => {
      this.spinner = false;
      console.log(res['ListOfSupplierActivity']);
      
      res?.listOfSupplierActivity.find(res => {
        res.employeeProfilePic = this.profilePic[this.uniqueEmployeeId.indexOf(res.employeeId)]
      });
      this.allActivities = res?.ListOfSupplierActivity;
      res.listOfSupplierActivity.filter(res => {
        this.supplierActivity.push(res);
        this.supplierActivities.push(res);
      });
    });
  }
  fetchEmpProfilePic(employeeId, event) {
    this.employeeService.fetchEmpProfilePic(employeeId).subscribe((res: any) => {
      this.uniqueEmployeeId.push(employeeId);
      if (res.isProfileImageExist) {
        this.profilePic.push(res.imageBase64Encoded)
      }
      else {
        this.profilePic.push("../../../assets/images/avatar.jpg")
      }
      if (this.employeesId.length == this.uniqueEmployeeId.length) {

        this.getSupplierActivity(this.pageNo, this.pageSize, event);

      }
    });
  }
  loadMoreActivity(event) {
    this.supplierActivities = []
    this.pageNo++;
    this.getSupplierActivity(this.pageNo, this.pageSize, event)

  }

}