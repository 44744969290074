import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { SkillModel } from '../../shared/models/skill-model';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  private stateUrl = 'api/v1/skill';

  constructor(private httpRequest: HttpRequestService) { }

  fetchSkills(): Observable<SkillModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchSkill(skillId): Observable<SkillModel> {
    return this.httpRequest.get(this.stateUrl + '/' + skillId);
  }

  updateSkill(skillModel: SkillModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, skillModel);
  }

  addSkill(skillModel: SkillModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, skillModel);
  }
}
