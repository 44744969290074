import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { EmployeeSectionDetails } from "src/app/shared/models/employee-section-model";
import { HttpRequestService } from "..";


@Injectable({
    providedIn: 'root'
})

export class EmployeeSectionService {


    private stateUrl = 'api/v1/employee-section';
    public sectionFieldsDetails = new BehaviorSubject<any>(null);

    sectionFieldDetails = this.sectionFieldsDetails.asObservable();


    constructor(private httpRequest: HttpRequestService) { }

    routeToSectionFieldsIds(employeeSectionDetails: EmployeeSectionDetails) {
        this.sectionFieldsDetails.next(employeeSectionDetails);
    }

    getAllEmployeeSectionAndFields(): Observable<EmployeeSectionDetails[]> {
        return this.httpRequest.get(this.stateUrl);
    }

    addEmployeeSection(employeeSection: EmployeeSection): Observable<any> {
        return this.httpRequest.post(this.stateUrl, employeeSection);
    }

    updateEmployeeSectionFields(employeeSection: EmployeeSection,sectionId: number): Observable<any> {
        return this.httpRequest.put(`${this.stateUrl}/${sectionId}`, employeeSection);
    }
}

export interface EmployeeSection {
    id?:number,
    sectionName?: string,
    sectionDescription?: string,
    fieldDefinitions?: EmployeeSectionFields[]
}

export interface EmployeeSectionFields {
    id?: number,
    labelName?: string,
    fieldDescription?: string,
    fieldType?: string,
    attributes?: AttributesMater
}
export interface AttributesMater {
    placeholder?: string,
    values?: string[],
    isRequired?: boolean
}