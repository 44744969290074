import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customValidators } from '../factory/common.service';
import { dateTimeFormatters } from '../factory/common.service';
export interface EmployeeDocumentModel {
    id:number,
    empId: number,
    documentName: string,
  
   
}
export class EmployeeDocumentFormBuilder {
    employeeDocumentModel: EmployeeDocumentModel = {
        id: null,
        empId: null,
        documentName: '',
        
    }
    employeeDocumentForm: FormGroup = new FormGroup({
        documentName: new FormControl(this.employeeDocumentModel.documentName, [Validators.required]),
        
        documentFile: new FormControl()
    });
    constructor() {
        this.employeeDocumentForm.valueChanges.subscribe(val => {
            this.employeeDocumentModel.documentName = val.documentName;
          
           
         
           
        });
    }
    setModelVals(res: EmployeeDocumentModel) {    
        this.employeeDocumentForm.controls.documentName.setValue(res.documentName);
        this.employeeDocumentModel.empId = res.empId;
        this.employeeDocumentModel.id = res.id;
    }
}