import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-select-control',
  templateUrl: './form-select-control.component.html',
  styleUrls: ['./form-select-control.component.css']
})
export class FormSelectControlComponent implements OnInit {

  // @Input() control: FormControl;
  @Input() label: any;
  @Input() errorName: any;
  @Input() multiple = false;
  @Input() class: any = 'inputGape';
  @Input() selectValue: any = '';
  @Input() selectname: any = '';
  @Input() data = [];
  @Input() inRow = false;
  @Input() isKey = true;
  @Input() width = '100%';
  @Input() optionalLabel = '';
  @Output() selectChangeEvent = new EventEmitter();
  @Input() searchable = true;
  @Input() control;
  @Input() fieldTypes;
    constructor() { }

    ngOnInit(): void {
    }

    selectChange(event) {
      this.selectChangeEvent.emit(event);
    }

    validator(control): boolean {
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      } else {
        return false;
      }
      return false
    }

  }