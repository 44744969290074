import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { QualificationModel } from '../../shared/models/qualification-model';

@Injectable({
  providedIn: 'root'
})
export class QualificationService {

  private stateUrl = 'api/v1/qualification';

  constructor(private httpRequest: HttpRequestService) { }

  fetchQualifications(): Observable<QualificationModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchQualification(qualificationId): Observable<QualificationModel> {
    return this.httpRequest.get(this.stateUrl + '/' + qualificationId);
  }

  updateQualification(qualificationModel: QualificationModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, qualificationModel);
  }

  addQualification(qualificationModel: QualificationModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, qualificationModel);
  }
}
