import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { supplierModelService } from 'src/app/core/services/add-supplier.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { supplierModel, SupplierFormBuilder } from 'src/app/shared/models/add-supplier';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.css']
})
export class supplierModelComponent implements OnInit {
  supplierAddForm: SupplierFormBuilder = new SupplierFormBuilder();
  supplierModel: supplierModel = {
    id: null,
    supplierName: '',
    contactNumber: null
  
  };
  id: number;

  constructor(private fb: FormBuilder,
    public router: Router,
    private addsupllierservice: supplierModelService,
  
   
    private messageService: MessageService
) {  }

  ngOnInit(): void {
    
  }


//   createGroup() {
//     this.Form = this.fb.group({
     
//       supplierName: [''],
//       contactNumber:[''],
     
//     });
//  }
//   onSave() {
//   let asset: AssetManagement = {};
//   asset = this.Form.value;
//   this.assetService.createAsset(asset).subscribe( data =>{
//     this.messageService.display('Asset added successfully. ', 'OK', 'success');
//     this.goToAssetList();
//   },
//     error => console.log(error));
//  }
  save() {
   
    // this.addsupllierservice.supplierModel().subscribe((res) => {
    //     console.log(res);
    
    //   this.messageService.display('Supplier Add successfully. ', 'OK', 'success');
    //     location.reload();
    // },
    // error=>console.log(error));

}
addUpdateSupplier(){
  this.supplierModel.id = this.supplierAddForm.id.value;
  this.supplierModel.supplierName = this.supplierAddForm.supplierName.value;
  this.supplierModel.contactNumber = this.supplierAddForm.contactNumber.value;
  //this.holidayModel.employeeLite = this.holidayForm.employeeLite.value;

 if (this.supplierModel.id) {
    this.addsupllierservice.updateSupplier(this.supplierModel,this.supplierModel.id).subscribe((res) => {
      this.messageService.display('Supplier  successfully updated!  ' , 'OK', 'success');
      this.router.navigate(['/supplier']);
    });
  } else {
    console.log(this.supplierModel)
    this.addsupllierservice.addSupplierModel(this.supplierModel).subscribe((res) => {
      this.messageService.display('Supplier  successfully added! ' , 'OK', 'success');
      this.router.navigate(['/supplier']);
    });
  }
}
// addSuppl(){
//    this.addsupllierservice.supplierModel(this.supplierModel).subscribe((res) => {
//       this.messageService.display('Supplier  successfully added! ' , 'OK', 'success');
//       this.router.navigate(['/supplier']);
//     });
// }
// updateSupplierr(){
//   this.addsupllierservice.updateSupplier(this.supplierModel,this.id).subscribe((res) => {
//     this.messageService.display('Supplier  successfully updated!  ' , 'OK', 'success');
//     this.router.navigate(['/supplier']);
//   });
// }
}
