import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssignAssetService } from 'src/app/core/services/assign-asset.service';
import { AasignAsset } from 'src/app/shared/models/aasign-asset';

@Component({
  selector: 'app-aasign-asset',
  templateUrl: './aasign-asset.component.html',
  styleUrls: ['./aasign-asset.component.css']
})
export class AasignAssetComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['id','type', 'assetName','assetTag', 'serialNo','model'];
  dataSource:MatTableDataSource<AasignAsset>;
  @ViewChild(MatSort) sort: MatSort;
 //@ViewChild('sort') sort: MatSort;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizes = [100,150,200,250,300];
  
  constructor(private viewAssignAssetSerive: AssignAssetService) { }

  ngOnInit(): void {
    this.viewAllAssignAsset();
    this.dataSource = new MatTableDataSource<AasignAsset>([]);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  viewAllAssignAsset(){
    this.viewAssignAssetSerive.viewAllAssignAsset().subscribe((res:AasignAsset[] )=>{
      this.dataSource = new MatTableDataSource<AasignAsset>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    
    

  }
  findHelpUrlPage(){

  }
}
