import { FormControl, Validators } from '@angular/forms';
import { customValidators } from '../factory/common.service';

export class LoginModel {
    email = new FormControl('', [Validators.required, customValidators.emailValidator()]); // zbhatuk@email.com, mprajapati@email.com
    password = new FormControl('', [Validators.required]);
    remember = new FormControl(false, []);
}
export interface LoginFormBuilder {
    email: string;
    password: string;
    remember: boolean;
}

// needs to be updated correctly