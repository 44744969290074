import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { JobTitleModel } from '../../shared/models/job-title-model';

@Injectable({
  providedIn: 'root'
})
export class JobTitleService {

  private stateUrl = 'api/v1/job-title';

  constructor(private httpRequest: HttpRequestService) { }

  fetchJobTitles(): Observable<JobTitleModel[]> {
    return this.httpRequest.get(this.stateUrl);
  }

  fetchJobTitle(jobTitleId): Observable<JobTitleModel> {
    return this.httpRequest.get(this.stateUrl + '/' + jobTitleId);
  }

  updateJobTitle(jobTitleModel: JobTitleModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl, jobTitleModel);
  }

  addJobTitle(jobTitleModel: JobTitleModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl, jobTitleModel);
  }
}
